import { ReactComponent as CloseIcon } from "../assets/close.svg"
import { ReactComponent as Logo } from "../assets/logo-white.svg"
import { ReactComponent as LogoSm } from "../assets/logo-white-sm.svg"
import { ReactComponent as VkIcon } from "../assets/vk.svg"
import { sanitizeHTML } from "shared/helpers"
import { useContacts } from "shared/store"
import { useNavigate } from "react-router-dom"

export function Menu({ onClose }) {
  const { phone, address, social } = useContacts()
  const { vk } = social

  return (
    <div className="h-[100%] flex-col bg-menu bg-cover px-[16px] pb-[16px] text-white max-md:overflow-auto max-md:pt-[17px] md:flex md:px-[45px] md:pb-[20px] xl:pb-[30px]">
      <div className="flex flex-none items-center justify-end border-b-[1px] border-white max-md:pb-[26px] md:h-[78px] md:h-[74px] xl:h-[82px]">
        <div className="link md:mt-[10px] md:pr-[290px]" onClick={onClose}>
          <CloseIcon className="h-[35px] w-[35px] cursor-pointer" />
        </div>
      </div>
      <div className="md:grid-12 flex-none max-md:hidden md:pt-[20px] md:pb-[16px] xl:pt-[30px] xl:pb-[50px]">
        <div className="text2 col-start-1 col-end-3">
          <a
            className="link"
            href="https://yandex.ru/maps/240/togliatti/house/ulitsa_marshala_zhukova_37a/YEAYdQdjQUcPQFtpfXlxcnhqbA==/?l=sat%2Cskl&ll=49.303699%2C53.503500&utm_source=share&z=17"
            target="_blank"
            rel="noreferrer"
            dangerouslySetInnerHTML={{ __html: sanitizeHTML(address.complex) }}
          />
        </div>
        <div className="text2 col-start-3 col-end-5">
          <a href={`tel:${phone}`}>{phone}</a>
        </div>
        <div className="col-start-11 col-end-13 items-center justify-end md:flex md:gap-[54px] xl:gap-[70px]">
          <div className="text2 link">
            <a href="mailto:sales@granirf.ru">sales@granirf.ru</a>
          </div>
          <div>
            {vk && (
              <div className="link flex-none">
                <a href={vk} target="_blank" rel="noreferrer">
                  <VkIcon className="h-[16px] w-[30px]" />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="border-b-[1px] border-white py-[16px] md:hidden">
        <div className="flex items-baseline">
          <div className="text2 flex-auto">
            <a href={`tel:${phone}`}>{phone}</a>
          </div>
          <div className="text2 flex-none">
            <a href="mailto:sales@granirf.ru">sales@granirf.ru</a>
          </div>
        </div>
        <div className="mt-[4px] flex items-baseline">
          <div className="flex-auto">
            <div className="text2">
              <a
                className="link"
                href="https://yandex.ru/maps/240/togliatti/house/ulitsa_marshala_zhukova_37a/YEAYdQdjQUcPQFtpfXlxcnhqbA==/?l=sat%2Cskl&ll=49.303699%2C53.503500&utm_source=share&z=17"
                target="_blank"
                rel="noreferrer"
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(address.complex) }}
              />
            </div>
          </div>
          <div className="flex-none">
            {vk && (
              <div className="link flex-none">
                <a href={vk} target="_blank" rel="noreferrer">
                  <VkIcon className="h-[11px] w-[18px]" />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="relative flex-auto items-center max-md:mt-[60px] md:flex">
        <div className="absolute top-0 bottom-0 right-0 left-0 m-auto w-[1px] bg-white max-md:hidden" />
        <div className="grid w-[100%] max-md:gap-[13px] md:grid-cols-2">
          <div className="flex flex-col gap-[13px] md:gap-[20px] md:pr-[40px] xl:pl-[45px]">
            <MenuOption href="genplan" onClick={onClose}>
              Выбрать лот
            </MenuOption>
            <MenuOption href="floor/9/1/1" onClick={onClose}>
              Паркинг
            </MenuOption>
          </div>
          <div className="flex flex-col gap-[13px] md:gap-[20px] md:pl-[40px] xl:pl-[45px]">
            <MenuOption href="/" onClick={onClose}>
              о проекте
            </MenuOption>
            <MenuOption href="/gallery" onClick={onClose}>
              Галерея
            </MenuOption>
            <MenuOption href="/contacts" onClick={onClose}>
              Контакты
            </MenuOption>
            <MenuLink href="/history" onClick={onClose} target="">
              Ход строительства
            </MenuLink>
            <MenuLink href="https://granirf.ru/documents">Документы</MenuLink>
          </div>
        </div>
      </div>
      <div className="flex flex-none justify-center pt-[160px] md:pt-[70px] xl:pt-[100px]">
        <Logo className="block max-md:hidden md:h-[132px] md:w-[479px] xl:h-[152px] xl:w-[546px]" />
        <LogoSm className="block h-[34px] w-[111px] md:hidden" />
      </div>
    </div>
  )
}

const MenuOption = ({ href, children, onClick }) => {
  const navigate = useNavigate()

  return (
    <div
      className="link h2 block cursor-pointer"
      onClick={() => {
        navigate(href)
        onClick()
      }}
    >
      {children}
    </div>
  )
}

const MenuLink = ({ href, children, target = "_blank" }) => {
  return (
    <a className="link h2 block cursor-pointer" href={href} target={target} rel="noreferrer">
      {children}
    </a>
  )
}
