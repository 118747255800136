import { ReactComponent as CloseIcon } from "../assets/close.svg"
import { cx } from "shared/helpers"

export function Modal({ children, onClose, variant = "default", title = "" }) {
  const variantCx = {
    default: "bg-brown-100 text-white",
    light: "bg-white text-black max-md:max-h-[80vh] max-md:overflow-hidden"
  }[variant]

  const closeVariantCx = {
    default: "md:mb-[5px] xl:mb-[8px]",
    light: "md:mt-[5px] xl:mt-[8px] text-black/50"
  }[variant]

  if (variant === "alert") {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <div className="btn box-border flex min-h-[90px] w-[fit-content] min-w-[290px] justify-between rounded-[50px] bg-brown-100 p-[29px] text-white">
          <div className="font-proximanova text-[12px] font-normal leading-[15px]">{children}</div>
          <div onClick={onClose} className="link cursor-pointer">
            <CloseIcon className="block h-[28px] w-[28px]" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={cx(
        "h-[100%] max-md:flex max-md:flex-col md:grid ",
        variant === "light" ? "md:block" : "md:grid-cols-2"
      )}
    >
      <div className={cx("max-md:flex-auto", { "md:hidden": variant === "light" })} onClick={onClose} />
      <div
        className={cx(
          "flex flex-col px-[16px] py-[40px] max-md:flex-none max-md:rounded-t-[12px] md:py-[58px] md:px-[45px] xl:py-[78px]",
          variantCx
        )}
      >
        <div className={cx("flex flex-none items-center", { "flex-row-reverse": variant === "light" })}>
          <div className={cx("h1 flex-auto max-md:py-[11px]", { "md:text-center": variant === "light" })}>{title}</div>
          <div
            className={cx("link flex-none cursor-pointer max-md:hidden", {
              "flex flex-col-reverse": variant === "light"
            })}
            onClick={onClose}
          >
            <div className={cx("link text-center font-proximanova text-[14px]", closeVariantCx)}>Закрыть</div>
            <div>
              <CloseIcon className="block h-[20px] w-[20px] md:h-[65px] md:w-[65px] xl:h-[82px] xl:w-[82px]" />
            </div>
          </div>
        </div>
        <div className="z-[1] flex flex-auto items-center justify-center max-md:hidden">{children}</div>
        <div className="z-[1] flex flex-auto items-center justify-center overflow-hidden md:hidden">
          <div className="h-[100%] overflow-y-auto">{children}</div>
        </div>
      </div>
    </div>
  )
}
