import { useEffect, useRef, useState } from "react"
import { cx } from "shared/helpers"
import { motion } from "framer-motion"

export function Progress({ list, active, variant = "dark", fixed = false, sm = false }) {
  const [heigth, setHeight] = useState()
  const [top, setTop] = useState()
  const ref = useRef()

  const variantCxActive = {
    dark: "black",
    light: "white",
    brown: "white",
    browndark: "brown-100"
  }[variant]

  const variantCx = {
    dark: "gray-400",
    light: "white/50",
    brown: "brown-400",
    browndark: "brown-200"
  }[variant]

  const variantBgCx = {
    dark: "bg-gray-400",
    light: "bg-white/50",
    brown: "bg-brown-400",
    browndark: "bg-brown-200"
  }[variant]

  useEffect(() => {
    const h = list.length ? ref.current.offsetHeight / list.length : 0
    setHeight(h)
    setTop(h * active)
  }, [active, list])

  return (
    <div className={cx("relative", { "grid h-[100%]": !fixed })} ref={ref}>
      <motion.div
        animate={{ y: top }}
        transition={{ default: { ease: "linear", duration: 0.4 } }}
        style={{ height: `${heigth}px` }}
        className={cx("absolute top-0 right-0 left-[16px] w-[3px]", `bg-${variantCxActive}`)}
      />
      {list.map((item, index) => (
        <div
          key={index}
          className={cx(
            "duration-400 flex items-center transition",
            { "h-[45px]": fixed && !sm },
            { "h-[28px]": fixed && sm },
            active === index ? `text-${variantCxActive}` : `text-${variantCx}`
          )}
        >
          <div className="w-[6px] flex-none">
            {index % 2 === 0 && <div className="h-[6px] w-[6px] rounded-[50%] border-[1.5px] border-current" />}
          </div>
          <div className="h-full flex-none px-[10px]">
            <div className={cx("h-full w-[3px]", variantBgCx)} />
          </div>
          <div className="w-[6px] flex-none">
            {index % 2 === 1 && <div className="h-[6px] w-[6px] rounded-[50%] border-[1.5px] border-current" />}
          </div>
        </div>
      ))}
    </div>
  )
}
