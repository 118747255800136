import { useEffect, useRef } from "react"
import { Button } from "shared/components/navigation"
import { SliderSm } from "./slider-sm"
import { addMockProps } from "shared/helpers"
import { gsap } from "gsap"
import { mock } from "../mock"

export const Section6 = addMockProps(function Section6({ lobby, receptionist }) {
  const container1 = useRef()
  const container2 = useRef()
  const wrapperRef = useRef()
  const inner = useRef()

  useEffect(() => {
    const ctx = gsap.context(self => {
      gsap
        .timeline({
          defaults: {
            ease: "none"
          },
          scrollTrigger: {
            trigger: wrapperRef.current,
            scrub: 2,
            start: "top center",
            end: () => "+=3000",
            toggleActions: "play reverse play reverse"
          }
        })
        .add("start")
        .fromTo(container1.current, { y: 0 }, { y: "-33%", duration: 10 }, "start")
        .fromTo(container2.current, { y: "-33%" }, { y: 0, duration: 10 }, "start")
        .to(wrapperRef.current, {
          y: -200,
          duration: 10
        })
    }, wrapperRef)
    return () => ctx.revert()
  }, [])

  return (
    <div className="overflow-hidden">
      <div
        ref={inner}
        className="wrap relative overflow-hidden bg-white pt-[30px] text-brown-100 md:pt-[60px] md:pb-[50px] xl:pt-[106px] xl:pb-[92px]"
      >
        <div>
          <div className="h1">Общественные пространства</div>
          <div className="mt-[32px] grid-cols-2 md:mt-[40px] md:grid md:gap-[95px] xl:mt-[60px] xl:gap-[176px]">
            <div>
              <div className="h2">Лобби</div>
              <div className="text mt-[22px] md:mt-[20px] md:max-w-[410px] xl:mt-[30px] xl:max-w-[616px]">
                На первом этаже расположен «Lounge room» для ожидания гостей и проведения важных переговоров. Так же
                можно пообщаться с соседями и просто насладиться панорамным видом на благоустроенный ландшафтный двор.
              </div>
            </div>
            <div className="mx-[-16px] mt-[20px] aspect-[296/320] md:hidden">
              <SliderSm slides={lobby} />
            </div>
            <div className="max-md:mt-[30px]">
              <div className="h2">Ресепшн</div>
              <div className="text mt-[22px] md:mt-[20px] md:max-w-[410px] xl:mt-[30px] xl:max-w-[616px]">
                К услугам жителей дома и их гостей предусмотрена зона рецепции с конcьержем, который любезно встретит
                вас и ваших гостей, предложит чашечку кофе и проследит за входящей корреспонденцией, своевременно
                передавая вам лично в руки.
              </div>
            </div>
            <div className="mx-[-16px] mt-[20px] aspect-[296/320] md:hidden">
              <SliderSm slides={receptionist} />
            </div>
          </div>
        </div>
        <div className="max-md:hidden" ref={wrapperRef}>
          <div className="grid grid-cols-2 overflow-hidden md:mt-[60px] md:h-[835px] md:gap-[95px] xl:mt-[70px] xl:h-[976px] xl:gap-[176px]">
            <div>
              <div ref={container1} className="grid will-change-transform md:gap-[42px] xl:gap-[60px]">
                {lobby.map(({ id, src }) => (
                  <div key={id}>
                    <img alt="" src={src} />
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div ref={container2} className="grid will-change-transform md:gap-[42px] xl:gap-[60px]">
                {receptionist.map(({ id, src }) => (
                  <div key={id}>
                    <img alt="" src={src} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[30px] items-center md:mt-[60px] md:grid md:grid-cols-2 md:gap-[20px] xl:mt-[120px]">
          <div>
            <div className="flex items-center md:gap-[30px] xl:gap-[60px]">
              <div className="number-1">150</div>
              <div className="max-md:hidden">
                <a target="_blank" rel="noreferrer" href="/mop">
                  <Button variant="small">3D тур</Button>
                </a>
              </div>
            </div>
            <div className="desc-num mt-[10px] md:mt-[17px] xl:mt-[30px]">
              М<sup>2</sup> общественных пространств
            </div>
            <div className="mt-[25px] md:hidden">
              <a target="_blank" rel="noreferrer" href="/mop">
                <Button variant="small">3D тур</Button>
              </a>
            </div>
          </div>
          <div className="text max-md:mt-[20px] md:pl-[20px] xl:pl-0">
            <div className="xl:grid xl:grid-cols-6 xl:gap-[20px]">
              <div className="xl:col-start-2 xl:col-end-6">
                Наши подъезды оформлены по дизайн-проекту. На всех этажах – одинаковый стиль и приятные цвета. Отделка
                будет радовать Вас долгие годы – мы использовали износостойкие материалы, специально созданные для
                общественных пространств. Интерьер холла выполнен в благородном неоклассическом стиле с элементами
                авторского искусства и природных оттенков. Дизайн одушевлен изысканным творчеством, в котором
                проработано все до мелочей. Здесь вы получите эстетическое удовольствие.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}, mock.spaces)
