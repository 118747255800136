export const placemarks = {
  placemarks: [
    {
      id: 43,
      complex_id: "marshal",
      name: "Аэрофотосъемка",
      address: null,
      description: null,
      type: "genplan",
      geo_lat: null,
      geo_lon: null,
      poly_genplan: '{"type":"Feature","properties":[],"geometry":{"type":"Point","coordinates":[950,70]}}',
      picture: null,
      mark_type_id: 22,
      link: "https://yandex.ru/maps/240/togliatti/?l=sat%2Cstv%2Csta&ll=49.318172%2C53.500692&panorama%5Bair%5D=true&panorama%5Bdirection%5D=211.180417%2C0.210577&panorama%5Bfull%5D=true&panorama%5Bid%5D=1368008657_696370587_23_1674404330&panorama%5Bpoint%5D=49.330322%2C53.503303&panorama%5Bspan%5D=118.156706%2C60.000000&utm_source=share&z=15",
      content_url: null,
      content_html: null,
      right: true,
      mark_type: {
        id: 22,
        complex_id: "marshal",
        mark_purpose: "infrastructure",
        name: "Аэрофотосъемка",
        icon: "0bcaa3d9ab0fa9551ecc537bd12cdd58",
        width: 40,
        height: 40,
        offcet_x: 20,
        offcet_y: 20,
        icon_url: require("../assets/i1.png")
      }
    },
    {
      id: 44,
      complex_id: "marshal",
      name: "3D тур",
      address: null,
      description: null,
      type: "genplan",
      geo_lat: null,
      geo_lon: null,
      poly_genplan: '{"type":"Feature","properties":[],"geometry":{"type":"Point","coordinates":[800,470]}}',
      picture: null,
      mark_type_id: 23,
      link: "/mop",
      content_url: null,
      content_html: null,
      mark_type: {
        id: 23,
        complex_id: "marshal",
        mark_purpose: "infrastructure",
        name: "3D тур",
        icon: "0bcaa3d9ab0fa9551ecc537bd12cdd58",
        width: 40,
        height: 40,
        offcet_x: 20,
        offcet_y: 20,
        icon_url: require("../assets/i2.png")
      }
    },
    {
      id: 45,
      complex_id: "marshal",
      name: "паркинг",
      address: null,
      description: null,
      type: "genplan",
      geo_lat: null,
      geo_lon: null,
      poly_genplan: '{"type":"Feature","properties":[],"geometry":{"type":"Point","coordinates":[420,485]}}',
      picture: null,
      mark_type_id: 24,
      link: "/floor/9/1/1",
      content_url: null,
      content_html: null,
      mark_type: {
        id: 24,
        complex_id: "marshal",
        mark_purpose: "infrastructure",
        name: "паркинг",
        icon: "0bcaa3d9ab0fa9551ecc537bd12cdd58",
        width: 40,
        height: 40,
        offcet_x: 20,
        offcet_y: 20,
        icon_url: require("../assets/i3.png")
      }
    }
  ]
}
