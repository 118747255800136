import { cx } from "shared/helpers"
import { useNavigate } from "react-router-dom"

export function PlacementLevelsSwitcher({ levels = {} }) {
  const navigate = useNavigate()
  function showFlat({ current, switchto }, level) {
    if (current !== level) {
      navigate(`/placement/${switchto}`)
    }
  }

  return (
    <div className="flex items-center gap-[20px] md:gap-[30px]">
      <Label
        level="upper"
        active={levels?.current === "upper"}
        right={true}
        onClick={() => {
          showFlat(levels, "lower")
        }}
      >
        Нижний уровень
      </Label>
      <div className="flex-none">
        <div
          className={cx("flex h-[23px] w-[44px] items-center rounded-[22px] border-[1.5px] border-gray-500 px-[6px]", {
            "justify-end": levels?.current === "upper"
          })}
        >
          <div className="h-[10px] w-[10px] rounded-[50%] bg-pink-100" />
        </div>
      </div>
      <Label
        level="lower"
        active={levels?.current === "upper"}
        onClick={() => {
          showFlat(levels, "upper")
        }}
      >
        Верхний уровень
      </Label>
    </div>
  )
}

const Label = ({ right, active, onClick, children }) => (
  <div
    className={cx(
      "flex-1 cursor-pointer text-[12px] font-semibold uppercase leading-[1.4] md:text-[14px] md:font-medium xl:text-[16px]",
      {
        "text-gray-500": !active,
        "text-right": right
      }
    )}
    onClick={onClick}
  >
    {children}
  </div>
)
