import { Footer } from "./footer"
import { Header } from "./header"
import { Outlet } from "react-router-dom"
import { Skeleton } from "./skeleton"

export function Layout({ headerEl = <Header />, footerEl = <Footer />, theme = "default" }) {
  return (
    <Skeleton headerEl={headerEl} footerEl={footerEl} theme={theme}>
      <Outlet />
    </Skeleton>
  )
}
