import { AnimatePresence, motion } from "framer-motion"
import { forwardRef, useEffect, useRef, useState } from "react"

import { Button } from "shared/components/navigation"
import { Link } from "react-router-dom"
import { PLACEMENT_STATUS } from "shared/constans"
import { PlacementTooltipLight } from "./placement-tooltip-light"
import Popup from "reactjs-popup"
import { PortalRenderer } from "./portal-renderer"
import { cx } from "shared/helpers"
import { useDomEvent } from "shared/hooks"
import { useNavigate } from "react-router-dom"
import { useOutsideClick } from "shared/hooks"
import { useViewbox } from "../hooks/viewbox"

export function FloorSchema({
  viewbox,
  schema,
  placements,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  scalable,
  onClick = () => {},
  placementUniCode,
  variant = "default"
}) {
  const navigate = useNavigate()
  const [activePlacement, setActivePlacement] = useState()
  const [activeUniCode, setActiveUniCode] = useState()
  const [elementPosition, setElementPosition] = useState({})
  const [tooltipPosition, setTooltipPosition] = useState({})
  const ref = useRef()
  const margin = 16
  const viewboxArray = viewbox.split(" ")

  const [hover, setHover] = useState(false)
  const setHoverValue = value => {
    if (scalable) setHover(value)
  }

  const closeTooltip = () => {
    setActivePlacement(null)
  }

  useEffect(() => {
    const height = ref.current?.offsetHeight || 0
    const width = ref.current?.offsetWidth || 0
    const top = elementPosition.top - height
    const left =
      elementPosition.left > 0
        ? elementPosition.left + width > window.innerWidth
          ? window.innerWidth - width - margin
          : elementPosition.left
        : 0
    setTooltipPosition({
      top: top,
      left: left
    })
  }, [activePlacement, elementPosition])

  useDomEvent(
    "scroll",
    () => {
      closeTooltip()
    },
    []
  )

  useOutsideClick(ref, () => {
    closeTooltip()
  })

  const [isSvgSchemaLoaded, setIsSvgSchemaLoaded] = useState(false)

  return (
    <Wrap active={hover} variant={variant}>
      {hover && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ default: { ease: "easeOut", delay: 0.2 } }}
          onMouseOver={() => {
            setHoverValue(false)
          }}
          className="fixed top-0 left-0 right-0 bottom-0 bg-gray-100/60 max-md:hidden"
          data-cover
        />
      )}
      <div
        className={cx("relative flex h-[100%] origin-top-right justify-center bg-white delay-100", {
          "h-[auto] cursor-pointer bg-white md:max-h-[134px] md:transition md:hover:z-[10] md:hover:scale-[2]": scalable
        })}
        onMouseEnter={event => {
          if (event.target.closest("svg")) setHoverValue(true)
        }}
        onMouseOut={event => {
          if (
            event.relatedTarget.dataset.cover ||
            event.relatedTarget.dataset.tooltip ||
            event.relatedTarget.closest("[data-tooltip]")
          )
            setHoverValue(false)
        }}
      >
        <div className={`relative aspect-[${viewboxArray[2] / viewboxArray[3]}] flex flex-1 max-md:flex-auto`}>
          <svg width="100%" height="100%" xmlSpace="preserve" viewBox={viewbox} data-name={`schema-${variant}`}>
            <image width="100%" height="100%" xlinkHref={schema} onLoad={() => setIsSvgSchemaLoaded(true)} />
            {placements.map(placement => (
              <PolygonWrap
                key={placement.uniCode}
                placement={placement}
                onClick={ev => {
                  setActivePlacement(placement)
                  setActiveUniCode(placement.uniCode)
                  setElementPosition(ev.target.getBoundingClientRect())
                  onClick(ev, placement)
                }}
                onMouseEnter={(ev, placement) => {
                  setActiveUniCode(placement.uniCode)
                  onMouseEnter(ev, placement)
                }}
                onMouseLeave={() => {
                  setActiveUniCode(null)
                  onMouseLeave()
                }}
                placementUniCode={placementUniCode}
                scalable={scalable}
                variant={variant}
              />
            ))}
          </svg>
          {!scalable && (
            <>
              {placements.map(placement => {
                return (
                  <AnimatePresence key={placement.uniCode}>
                    {activeUniCode !== placement.uniCode && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ default: { ease: "easeOut" } }}
                      >
                        {placement.placementTypeId === "apartment" && (
                          <Label placement={placement} viewbox={viewbox} activePlacement variant={variant} />
                        )}
                        {placement.placementTypeId !== "apartment" && (
                          <LabelSimple
                            key={String(isSvgSchemaLoaded)}
                            placement={placement}
                            viewbox={viewbox}
                            activePlacement
                            variant={variant}
                          />
                        )}
                      </motion.div>
                    )}
                  </AnimatePresence>
                )
              })}
            </>
          )}
        </div>
      </div>
      {variant === "sm" && (
        <AnimatePresence>
          {activePlacement && (
            <motion.div
              ref={ref}
              style={{ top: `${tooltipPosition.top}px`, left: `${tooltipPosition.left}px` }}
              className="fixed top-[auto] z-[100] min-w-[262px] bg-white p-[15px] shadow-3xl md:hidden"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ default: { ease: "easeOut" } }}
            >
              <PlacementTooltipLight placement={activePlacement} elementPosition={elementPosition}>
                <div className="flex justify-center md:hidden">
                  <Link to={`/placement/${activePlacement.uniCode}`}>
                    <Button
                      style={{ width: 142, height: 51 }}
                      onClick={() => {
                        closeTooltip()
                        navigate(`/placement/${activePlacement.uniCode}`)
                      }}
                    >
                      смотреть
                    </Button>
                  </Link>
                </div>
              </PlacementTooltipLight>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </Wrap>
  )
}

const Label = ({ placement, viewbox, variant = "default" }) => {
  const viewboxArray = viewbox.split(" ")
  const polygon = document.querySelector(`[data-polygon="${placement.uniCode}-${variant}"]`)
  const bbox = polygon ? polygon.getBBox() : { x: 0, y: 0, width: 0, height: 0 }
  const posX = ((bbox.x + bbox.width / 2) / viewboxArray[2]) * 100
  const posY = ((bbox.y + bbox.height / 2) / viewboxArray[3]) * 100

  return (
    <div
      style={{ left: `${posX}%`, top: `${posY}%` }}
      className={cx(
        "pointer-events-none absolute flex h-[42px] w-[42px] translate-x-[-50%] translate-y-[-50%] cursor-pointer items-center justify-center rounded-[50%] bg-white shadow-2xl md:h-[50px] md:w-[50px] xl:h-[67px] xl:w-[67px]",
        placement.status === PLACEMENT_STATUS.Sold ? "text-black/50" : "text-black"
      )}
    >
      <div className="flex items-baseline gap-[2px]">
        <div className="font-proximanova text-[15px] md:text-[17px]">№</div>
        <div className="text-[15px] md:text-[25px]">{placement.placementNumber}</div>
      </div>
    </div>
  )
}

const LabelSimple = ({ placement, variant = "default" }) => {
  const { x, y, f } = useViewbox(placement, variant)
  return (
    <div
      style={{ left: `${x}px`, top: `${y}px` }}
      className={cx(
        "pointer-events-none absolute flex h-[21px] w-[21px] translate-x-[-50%] translate-y-[-50%] cursor-pointer items-center justify-center md:h-[25px] md:w-[25px] xl:h-[34px] xl:w-[34px]",
        placement.status === PLACEMENT_STATUS.Sold ? "text-black/50" : "text-black"
      )}
    >
      <div className="flex items-baseline gap-[2px]">
        <div className="origin-left font-proximanova" style={{ fontSize: 12 * f }}>
          №
        </div>
        <div className="origin-left" style={{ fontSize: 18 * f }}>
          {placement.placementNumber}
        </div>
      </div>
    </div>
  )
}

const PolygonWrap = ({ placement, onClick, onMouseEnter, onMouseLeave, placementUniCode, scalable, variant }) => {
  const ref = useRef()
  const closeTooltip = () => ref.current.close()

  if (variant === "sm")
    return (
      <Polygon
        placement={placement}
        key={placement.uniCode}
        onClick={ev => onClick(ev, placement)}
        active={placement.uniCode === placementUniCode}
        variant={variant}
      />
    )
  return (
    <Link
      to={`/placement/${placement.uniCode}`}
      onMouseEnter={ev => onMouseEnter(ev, placement)}
      onMouseLeave={onMouseLeave}
      onMouseOver={ev => {
        if (scalable && ev.target.closest("[data-tooltip]")) {
          closeTooltip()
        }
      }}
    >
      <Popup
        ref={ref}
        trigger={
          <Polygon
            placement={placement}
            active={placement.uniCode === placementUniCode}
            print={variant === "print"}
            variant={variant}
          />
        }
        on={["hover"]}
        position="left center"
        arrow={false}
        mouseLeaveDelay={200}
        disabled={!scalable}
      >
        <div className="min-w-[200px] bg-white p-[15px] shadow-3xl" data-tooltip>
          <PlacementTooltipLight placement={placement} />
        </div>
      </Popup>
    </Link>
  )
}

const Polygon = forwardRef(({ placement, active = false, print, variant = "default", ...props }, ref) => {
  const points = placement.polyScheme ?? null
  return (
    <polygon
      data-polygon={`${placement.uniCode}-${variant}`}
      ref={ref}
      vactor-effect="non-scaling-stroke"
      className={cx(
        { "stroke-brown-100/70 stroke-[0.2%] hover:stroke-[0.6%]": placement.placementTypeId !== "apartment" },
        "cursor-pointer fill-transparent stroke-[0.8%] hover:stroke-brown-200",
        {
          "fill-brown-300/60": placement.status === PLACEMENT_STATUS.Booked && !print,
          "fill-gray-900/70": placement.status === PLACEMENT_STATUS.Sold && !print,
          "fill-brown-200/80 stroke-none": active
        }
      )}
      points={points}
      {...props}
    />
  )
})

const Wrap = ({ children, variant, active }) => {
  if (variant === "sm") return <>{children}</>
  return <PortalRenderer active={active}>{children}</PortalRenderer>
}
