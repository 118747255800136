import { useEffect, useMemo, useRef, useState } from "react"
import { AnimatePresence } from "framer-motion"
import { createPortal } from "react-dom"

export function PortalRenderer({ children, active = false }) {
  const $container = useRef()
  const [rect, setRect] = useState({})

  useEffect(() => {
    function invalidateRect() {
      if ($container.current) {
        const rect = $container.current.getBoundingClientRect()
        setRect(rect)
      }
    }
    invalidateRect()
    window.addEventListener("resize", invalidateRect)
    return () => window.removeEventListener("resize", invalidateRect)
  }, [])

  const style = useMemo(
    () =>
      RECT_KEYS.reduce((acc, cur) => {
        acc[cur] = rect[cur] + "px"
        return acc
      }, {}),
    [rect]
  )

  return (
    <>
      <div ref={$container} className="h-full max-md:hidden">
        {createPortal(
          <AnimatePresence mode="wait">
            {active && (
              <div className="fixed z-[100]" style={style}>
                {children}
              </div>
            )}
          </AnimatePresence>,
          document.body
        )}
        {!active && <>{children}</>}
      </div>
      <div className="md:hidden">{children}</div>
    </>
  )
}

const RECT_KEYS = ["width", "height", "top", "left"]
