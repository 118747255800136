import { useEffect, useRef } from "react"
import { ReactComponent as Text1Icon } from "../assets/business.svg"
import { ReactComponent as Text2Icon } from "../assets/business1.svg"
import { ReactComponent as Text3Icon } from "../assets/class.svg"
import { ReactComponent as Text4Icon } from "../assets/class1.svg"
import { gsap } from "gsap"

export function Section7() {
  const wrapperRef = useRef()
  const image1 = useRef()
  const image2 = useRef()

  useEffect(() => {
    const ctx = gsap.context(self => {
      gsap
        .timeline({
          defaults: {
            ease: "none"
          },
          scrollTrigger: {
            trigger: wrapperRef.current,
            scrub: true,
            start: "top center",
            end: () => "+=" + 1.5 * wrapperRef.current.offsetHeight,
            toggleActions: "play reverse play reverse"
          }
        })
        .add("start")
        .to(
          image1.current,
          {
            x: "-50%",
            duration: 3
          },
          "start"
        )
        .to(
          image2.current,
          {
            x: "50%",
            duration: 3
          },
          "start"
        )
    }, wrapperRef)
    return () => ctx.revert()
  }, [])

  return (
    <div
      ref={wrapperRef}
      className="lots-section relative overflow-hidden text-white max-md:aspect-[320/588] md:h-[100vh]"
    >
      <img alt="" src={require("../assets/l.jpg")} className="h-full w-full object-cover" />
      <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center">
        <div className="h1">Лоты</div>
      </div>
      <div className="absolute left-0 right-0 top-[60px] bottom-[60px] flex flex-col justify-between md:top-[40px] md:bottom-[40px] xl:top-[50px] xl:bottom-[50px]">
        <div>
          <div ref={image1} className="flex w-[200vw] will-change-transform">
            <div className="w-[100vw] px-[16px] md:px-[45px]">
              <Text1Icon className="h-[auto] w-full" />
            </div>
            <div className="w-[100vw] px-[16px] md:px-[45px]">
              <Text2Icon className="h-[auto] w-full" />
            </div>
          </div>
        </div>
        <div>
          <div ref={image2} className="flex w-[200vw] translate-x-[-50%] will-change-transform">
            <div className="w-[100vw] px-[16px] md:px-[45px]">
              <Text3Icon className="h-[auto] w-full" />
            </div>
            <div className="w-[100vw] px-[16px] md:px-[45px]">
              <Text4Icon className="h-[auto] w-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
