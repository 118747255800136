import { AgreementPage, PolicyPage } from "features/documents"
import { BlankLayout, Footer, Header, Layout } from "shared/components/layout"
import { FloorPage, PlacementPage, PrintPlacementPage } from "features/placements"
import { GalleryDetailPage, GalleryPage } from "features/gallery"
import { GenplanPage, SuncalcPage } from "features/genplan"
import { HistoryDetailPage, HistoryPage } from "features/history"

import { ContactsPage } from "features/contacts"
import { LandingPage } from "features/landing"
import { MopPage } from "features/mop"
import { NotFound } from "shared/components/not-found"
import { createBrowserRouter } from "react-router-dom"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "agreement",
        element: <AgreementPage />
      },
      {
        path: "policy",
        element: <PolicyPage />
      }
    ]
  },
  {
    element: <Layout footerEl={<Footer md={false} />} />,
    errorElement: <NotFound />,
    children: [
      {
        path: "floor/:building/:section",
        element: <FloorPage />
      },
      {
        path: "floor/:building/:section/:floor",
        element: <FloorPage />
      },
      {
        path: "placement/:id",
        element: <PlacementPage />
      },
      {
        path: "mop",
        element: <MopPage />
      }
    ]
  },
  {
    element: <BlankLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "placement/:id/print",
        element: <PrintPlacementPage />
      },
      {
        path: "history/:id",
        element: <HistoryDetailPage />
      },
      {
        path: "suncalc",
        element: <SuncalcPage />
      },
      {
        path: "gallery/:id",
        element: <GalleryDetailPage />
      }
    ]
  },
  {
    element: <Layout headerEl={<Header variant="landing" />} footerEl={null} />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <LandingPage />
      }
    ]
  },
  {
    element: <Layout headerEl={<Header variant="overlay" />} footerEl={null} />,
    errorElement: <NotFound />,
    children: [
      {
        path: "contacts",
        element: <ContactsPage />
      },
      {
        path: "gallery",
        element: <GalleryPage />
      }
    ]
  },
  {
    element: <Layout headerEl={<Header variant="fixed" />} footerEl={null} />,
    errorElement: <NotFound />,
    children: [
      {
        path: "genplan",
        element: <GenplanPage />
      }
    ]
  },
  {
    element: <Layout headerEl={<Header variant="fixed" theme="dark" />} theme="dark" />,
    errorElement: <NotFound />,
    children: [
      {
        path: "history",
        element: <HistoryPage />
      }
    ]
  }
])
