import { Button } from "shared/components/navigation"
import { Link } from "react-router-dom"

export const PolicyPage = () => {
  return (
    <div className="wrap max-md:py-[50px]">
      <h1 className="h1">Политика</h1>
      <div className="md:grid-12 mt-[20px] md:mt-[40px]">
        <div className="h3 md:col-start-1 md:col-end-6">
          ПОЛИТИКА В ОБЛАСТИ ОБРАБОТКИ И ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ В ОБЩЕСТВЕ С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ
          СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК «ГРАНИТ»
        </div>
        <div className="text max-md:mt-[50px] md:col-start-7 md:col-end-13">
          <div>
            1. Общие положения. Настоящая политика в области обработки и защиты персональных данных (далее - Политика):
            <Ul>
              <li>
                разработана в целях обеспечения реализации требований законодательства РФ в области обработки
                персональных данных субъектов персональных данных;
              </li>
              <li>
                раскрывает основные категории персональных данных, обрабатываемых в ООО СЗ «ГРАНИТ» (далее - Оператор),
                цели, способы и принципы обработки Оператором персональных данных, права и обязанности Оператора при
                обработке персональных данных, права субъектов персональных данных, а также перечень мер, применяемых
                Оператором в целях обеспечения безопасности персональных данных при их обработке в
                информационно-телекоммуникационной сети;
              </li>
              <li>
                является общедоступным документом, декларирующим концептуальные основы деятельности Оператора при
                обработке персональных данных.
              </li>
            </Ul>
            В настоящей Политике используются следующие понятия и термины:
            <Ul>
              <li>
                Оператор - государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно
                или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а
                также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке,
                действия (операции), совершаемые с персональными данными;
              </li>
              <li>
                Обработка персональных данных - получение, хранение, комбинирование, передача или любое другое
                использование персональных данных.
              </li>
              <li>
                ИСПДн (Информационная система персональных данных) – информационная система, представляющая собой
                совокупность персональных данных, содержащихся в базе данных, а также информационных технологий и
                технических средств, позволяющих осуществлять обработку таких персональных данных с использованием
                средств автоматизации или без использования таких средств.
              </li>
              <li>
                Персональные данные - любая информация, относящаяся к прямо или косвенно определенному, или
                определяемому физическому лицу (субъекту персональных данных).
              </li>
              <li>
                Предоставление персональных данных - действия, направленные на раскрытие персональных данных
                определенному лицу или определенному кругу лиц;
              </li>
              <li>
                Блокирование персональных данных - временное прекращение обработки персональных данных (за исключением
                случаев, если обработка необходима для уточнения персональных данных);
              </li>
              <li>
                Уничтожение персональных данных - действия, в результате которых становится невозможным восстановить
                содержание персональных данных в информационной системе персональных данных и (или) в результате которых
                уничтожаются материальные носители персональных данных;
              </li>
              <li>
                Обезличивание персональных данных - действия, в результате которых становится невозможным без
                использования дополнительной информации определить принадлежность персональных данных конкретному
                субъекту персональных данных;
              </li>
              <li>
                Сайт – совокупность размещенных в сети Интернет веб-страниц, объединенных единой темой, дизайном и
                единым адресным пространством домена;
              </li>
              <li>
                Администрация Сайта (Администрация) – лица, уполномоченные Владельцем Сайта на осуществление управления
                Сайтом и иные действия, связанные с его использованием. Администрация Сайта действует от имени Владельца
                Сайта, если иное не будет указано отдельно.
              </li>
            </Ul>
          </div>
          <div className="mt-[40px] md:mt-[70px] xl:mt-[90px]">
            <Link to="/agreement">
              <Button>
                согласие на
                <br /> обработку данных
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

const Ul = ({ children }) => <ul className="ml-[12px] list-inside list-disc">{children}</ul>
