import { useEffect, useRef } from "react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { gsap } from "gsap"

export function Section4() {
  const wrapperRef = useRef()
  const inner = useRef()

  useEffect(() => {
    const ctx = gsap.context(self => {
      ScrollTrigger.matchMedia({
        "(min-width: 960px)": function () {
          gsap.timeline({
            defaults: {
              ease: "none"
            },
            scrollTrigger: {
              trigger: wrapperRef.current,
              pin: true,
              scrub: true,
              start: "top top",
              end: () => "+=2000"
            }
          })

          gsap
            .timeline({
              defaults: {
                ease: "none"
              },
              scrollTrigger: {
                trigger: inner.current,
                scrub: true,
                start: "top bottom",
                end: () => "+=" + 2 * inner.current.offsetHeight
              }
            })
            .fromTo(
              inner.current,
              { y: -inner.current.offsetHeight },
              {
                y: 0,
                ease: "none"
              }
            )
        }
      })
    }, wrapperRef)
    return () => ctx.revert()
  }, [])

  return (
    <div className="md:h-[1200px]">
      <div ref={wrapperRef} className="relative aspect-[320/585] w-full overflow-hidden text-white md:h-[100vh]">
        <div ref={inner} className="h-full w-full will-change-transform">
          <img alt="" src={require("../assets/i.jpg")} className="h-full w-full object-cover" />
          <div className="h2 absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
            Благоустройство
          </div>
        </div>
      </div>
    </div>
  )
}
