import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { Button } from "shared/components/navigation"
import { Link } from "react-router-dom"
import { PlacemarkMarker } from "./placemark-marker"
import { declOfNum } from "shared/helpers"
import { useNavigate } from "react-router-dom"

export function GenplanRender({ genplan = [], placemarks = [] }) {
  const building = genplan[0]
  const section = building.sections[0]
  const floors = section?.floors || []
  const [currentFloor, setCurrentFloor] = useState()
  const [animating, setAnimating] = useState()
  const image = useRef()
  const navigate = useNavigate()
  const svg = useRef()
  const [x, setX] = useState()
  const [scale, setScale] = useState()
  const height = 668
  const width = 1366

  useEffect(() => {
    function resize() {
      const svgWidth = (width / height) * window.innerHeight
      const xOffset = (window.innerWidth - svgWidth) / 2
      setScale(xOffset < 0 ? 1 : window.innerWidth / svgWidth)
      setX(xOffset < 0 ? xOffset : 0)
    }
    resize()
    window.addEventListener("resize", resize)
    return () => window.removeEventListener("resize", resize)
  }, [])

  return (
    <div className="relative h-full w-full overflow-hidden">
      <svg
        ref={svg}
        className="m-[auto] block h-full snap-center overflow-hidden max-md:translate-x-[-50%]"
        viewBox="0 0 1366 668"
        style={{
          width: "auto",
          transform:
            window.innerHeight > window.innerWidth
              ? `translateX(-50%) scale(${scale})`
              : `translateX(${x}px) scale(${scale})`
        }}
      >
        <image
          ref={image}
          overflow="visible"
          width={width}
          height={height}
          className="h-full w-full"
          href={require("../assets/genplan_v3.jpg")}
        />
        {floors.map(floor => {
          return (
            <Link
              key={floor.number}
              to={`/floor/${building.id}/${section.number}/${floor.number}`}
              className="max-md:hidden"
            >
              <path
                d={floor.polyGenplan}
                fill="transparent"
                className="hover:fill-white/50"
                onMouseEnter={() => setCurrentFloor(floor)}
                onMouseLeave={() => setCurrentFloor(null)}
              />
            </Link>
          )
        })}
        {floors.map(floor => {
          return (
            <path
              key={floor.number}
              d={floor.polyGenplan}
              fill="transparent"
              className="hover:fill-white/50 md:hidden"
              onMouseEnter={() => setCurrentFloor(floor)}
              onMouseLeave={() => setCurrentFloor(null)}
            />
          )
        })}
        {placemarks.map(placemark => {
          return <PlacemarkMarker key={placemark.id} placemark={placemark} />
        })}
      </svg>

      <div className="margin-[auto] pointer-events-none absolute left-0 bottom-[80px] max-md:right-0 max-md:px-[16px] md:left-[45px] md:top-0 md:bottom-0 md:flex md:items-center">
        <AnimatePresence mode="wait">
          {currentFloor && (
            <div className="relative h-[105px] md:h-[216px] xl:h-[300px]">
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 300, opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ default: { ease: "easeOut", duration: 0.7 } }}
                onAnimationStart={() => {
                  setAnimating(true)
                }}
                onAnimationComplete={() => {
                  setAnimating(false)
                }}
                className="absolute bottom-0 min-w-[400px] overflow-hidden max-md:right-0 max-md:left-0"
              >
                <div className="absolute bottom-0 flex items-baseline gap-[20px] text-white">
                  <div className="max-md:flex max-md:w-full max-md:justify-between">
                    <div>
                      <div className="text-center text-[100px] leading-[80px] md:w-[120px] md:text-[200px] md:leading-[170px] xl:w-[140px] xl:text-[300px] xl:leading-[231px]">
                        {currentFloor.number}
                      </div>
                      <div className="desc-num mt-[20px] text-center">
                        Этаж{" "}
                        <span className="text md:hidden">
                          / {currentFloor.flatsAvailable}{" "}
                          {declOfNum(currentFloor.flatsAvailable, ["лот", "лота", "лотов"])}
                        </span>
                      </div>
                    </div>
                    <div className="pointer-events-auto pt-[10px] md:hidden">
                      <Button
                        variant="small"
                        onMouseEnter={() => {
                          // WTF: в том случае, когда мыжка находит на кнопку
                          // засетим в текущий этаж - текущий этаж
                          // дабы сохранить последнее состояние
                          setCurrentFloor(currentFloor)
                        }}
                        onClick={() => {
                          navigate(`/floor/${building.id}/${section.number}/${currentFloor.number}`)
                        }}
                      >
                        смотреть
                      </Button>
                    </div>
                  </div>
                  <div className="text max-md:hidden">
                    / {currentFloor.flatsAvailable} {declOfNum(currentFloor.flatsAvailable, ["лот", "лота", "лотов"])}
                  </div>
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
        {!currentFloor && !animating && (
          <motion.div
            className="h2 pointer-events-none text-white"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ default: { ease: "easeOut", duration: 0.5 } }}
          >
            выберите
            <br /> этаж
          </motion.div>
        )}
      </div>
    </div>
  )
}
