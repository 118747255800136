import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/controller"
import "swiper/css/lazy"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Controller, EffectFade, Lazy, Navigation } from "swiper"
import { useEffect, useRef, useState } from "react"

import { ReactComponent as ArrowNextIcon } from "../assets/arrow-next.svg"
import { ReactComponent as ArrowPrevIcon } from "../assets/arrow-prev.svg"
import { Progress } from "shared/components/progress"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { addMockProps } from "shared/helpers"
import { cx } from "shared/helpers"
import { gsap } from "gsap"
import { mock } from "../mock"

SwiperCore.use([Autoplay, EffectFade, Navigation, Lazy, Controller])

export const Section3 = addMockProps(function Section3({ data }) {
  const wrapperRef = useRef()
  const inner = useRef()
  const [swiper, setSwiper] = useState()
  const [imgSwiper, setImgSwiper] = useState()
  const [active, setActive] = useState(0)

  useEffect(() => {
    if (swiper && imgSwiper && swiper.controller && imgSwiper.controller) {
      swiper.controller.control = imgSwiper
      imgSwiper.controller.control = swiper
    }

    const ctx = gsap.context(self => {
      ScrollTrigger.matchMedia({
        "(min-width: 960px)": function () {
          gsap.timeline({
            defaults: {
              ease: "none"
            },
            scrollTrigger: {
              trigger: wrapperRef.current,
              pin: true,
              scrub: true,
              start: "top top",
              end: () => "+=2000",
              toggleActions: "play none play none",
              onUpdate(ev) {
                if (swiper && swiper.slides) {
                  let progress = ev.progress
                  let count = swiper.slides.length * 2
                  let index = Math.round(count * progress)
                  swiper.slideTo(index)
                }
              }
            }
          })

          gsap
            .timeline({
              defaults: {
                ease: "none"
              },
              scrollTrigger: {
                trigger: inner.current,
                scrub: true,
                start: "top bottom",
                end: () => "+=" + 2 * inner.current.offsetHeight
              }
            })
            .fromTo(
              inner.current,
              { y: -inner.current.offsetHeight },
              {
                y: 0,
                ease: "none"
              }
            )
        }
      })
    }, wrapperRef)
    return () => ctx.revert()
  }, [swiper, imgSwiper])

  let params = {
    preloadImages: false,
    lazy: true,
    navigation: {
      prevEl: ".swiper-prev",
      nextEl: ".swiper-next"
    },
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    loop: false,
    speed: 500,
    slidesPerView: 1,
    onSlideChange: swiper => setActive(swiper.realIndex)
  }

  return (
    <div className="relative md:h-[2000px]">
      <div ref={wrapperRef} className="h-[100vh] transform-gpu overflow-hidden bg-brown-100 text-white max-md:hidden">
        <div className="grid-12 h-full px-[45px] pt-[60px] will-change-transform xl:pt-[90px]" ref={inner}>
          <div className="col-start-1 col-end-6 flex flex-col">
            <div className="h1 flex-auto">Расположение</div>
            <div className="flex-none">
              <div className="relative">
                <Swiper {...params} className="h-full" onSwiper={setSwiper}>
                  {data.map(({ value, text, id }) => (
                    <SwiperSlide
                      key={id}
                      className="absolute bottom-0 left-0 h-full min-h-[220px] w-full bg-brown-100 opacity-0"
                    >
                      <div className="number-1 leading-[1]">{value}</div>
                      <div className="desc-num mt-[10px]" dangerouslySetInnerHTML={{ __html: text }} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="text mt-[20px] max-w-[420px] pb-[42px] xl:max-w-[504px] xl:pb-[60px]">
                Удобная транспортная доступность, развитая инфраструктура и природа вокруг — вот что делает клубный дом
                «Маршал» идеальным местом для жизни.
              </div>
            </div>
          </div>
          <div className="col-start-6 col-end-13 flex flex-col">
            <div className="flex-none">
              <div className="h2">Ощутите себя частью природы, почувствуйте её поэзию и красоту</div>
              <div className="mt-[20px] flex md:gap-[30px] xl:mt-[25px] xl:gap-[38px]">
                <div className="btn">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://yandex.ru/maps/240/togliatti/house/ulitsa_marshala_zhukova_37a/YEAYdQdjQUcPQFtpfXlxcnhqbA==/?l=sat%2Cskl&ll=49.303699%2C53.503500&utm_source=share&z=17"
                  >
                    карта
                  </a>
                </div>
                <div className="btn">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://yandex.ru/maps/240/togliatti/?l=sat%2Cstv%2Csta&ll=49.318172%2C53.500692&panorama%5Bair%5D=true&panorama%5Bdirection%5D=211.180417%2C0.210577&panorama%5Bfull%5D=true&panorama%5Bid%5D=1368008657_696370587_23_1674404330&panorama%5Bpoint%5D=49.330322%2C53.503303&panorama%5Bspan%5D=110.105652%2C60.000000&z=15"
                  >
                    панорама
                  </a>
                </div>
              </div>
            </div>
            <div className="grid flex-auto grid-cols-7 gap-[20px] pt-[20px]">
              <div className="col-start-1 col-end-2 flex items-center">
                <div>
                  <Progress list={data} active={active} variant="brown" fixed={true} />
                </div>
              </div>
              <div className="relative col-start-2 col-end-8 flex justify-end">
                <Swiper {...params} className="h-full" onSwiper={setImgSwiper}>
                  {data.map(({ map, id }) => (
                    <SwiperSlide
                      key={id}
                      className="absolute top-0 bottom-0 left-0 flex transform-gpu justify-end bg-brown-100"
                    >
                      <div className="h-full">{map}</div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrap bg-brown-100 text-white md:hidden">
        <div className="h1 flex-auto">Расположение</div>
        <div className="mt-[30px] text-[20px] uppercase leading-[1.3]">
          Ощутите себя частью природы, почувствуйте её поэзию и красоту
        </div>
        <div className="mt-[20px] flex gap-[34px]">
          <div className="btn">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://yandex.ru/maps/240/togliatti/house/ulitsa_marshala_zhukova_37a/YEAYdQdjQUcPQFtpfXlxcnhqbA==/?l=sat%2Cskl&ll=49.303699%2C53.503500&utm_source=share&z=17"
            >
              карта
            </a>
          </div>
          <div className="btn">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://yandex.ru/maps/240/togliatti/?l=sat%2Cstv%2Csta&ll=49.318172%2C53.500692&panorama%5Bair%5D=true&panorama%5Bdirection%5D=211.180417%2C0.210577&panorama%5Bfull%5D=true&panorama%5Bid%5D=1368008657_696370587_23_1674404330&panorama%5Bpoint%5D=49.330322%2C53.503303&panorama%5Bspan%5D=110.105652%2C60.000000&z=15"
            >
              панорама
            </a>
          </div>
        </div>
        <div className="relative">
          <Swiper {...params}>
            {data.map(({ value, text, map, id }) => (
              <SwiperSlide key={id} className="bg-brown-100">
                <div className="number-1 text-[100px] leading-[1.2]">{value}</div>
                <div className="desc-num" dangerouslySetInnerHTML={{ __html: text }} />
                <div className="mt-[36px]">{map}</div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="absolute top-[-4px] right-0 z-[10]">
            <div
              className={cx("swiper-next mt-[4px] cursor-pointer", {
                "text-white/50": active + 1 === data.length
              })}
            >
              <ArrowNextIcon />
            </div>
            <div
              className={cx("swiper-prev cursor-pointer", {
                "text-white/50": active === 0
              })}
            >
              <ArrowPrevIcon />
            </div>
          </div>
        </div>
        <div className="text mt-[20px]">
          Удобная транспортная доступность, развитая инфраструктура и природа вокруг — вот что делает клубный дом
          «Маршал» идеальным местом для жизни.
        </div>
      </div>
    </div>
  )
}, mock.location)
