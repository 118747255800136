import { GenplanRender } from "../components/genplan-render"
import { Link } from "react-router-dom"
import { addMockProps } from "shared/helpers"
import { mock } from "../mock"
import { useGenplan } from "../api"
import { useThrowError } from "shared/hooks"

export const GenplanPage = addMockProps(function GenplanPage({ placemarks }) {
  const { error, data: { data } = {} } = useGenplan()
  useThrowError(error)

  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 w-full">
      {data && <GenplanRender genplan={data} placemarks={placemarks} />}
      <div className="absolute left-[16px] bottom-0 right-[16px] z-[10] flex justify-between gap-[30px] border-t-[1px] border-white py-[16px] text-white md:left-[45px] md:right-[45px] md:justify-start md:py-[40px]">
        <div className="btn link">
          <Link to="/suncalc">движение солнца</Link>
        </div>
        {/* <div className="btn link">
          <Link to="/pano/mop">3D тур</Link>
        </div> */}
        <div className="btn link">
          <Link to="/floor/9/1/1">паркинг</Link>
        </div>
      </div>
    </div>
  )
}, mock.placemarks)
