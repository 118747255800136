import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"

import { ReactComponent as BurgerIcon } from "../assets/burger.svg"
import { Link } from "react-router-dom"
import { ReactComponent as LogoIcon } from "../assets/logo.svg"
import { ReactComponent as LogoLandingIcon } from "../assets/logo-landing.svg"
import { ReactComponent as LogoSmIcon } from "../assets/logo-sm.svg"
import { Menu } from "./menu"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { cx } from "shared/helpers"
import { gsap } from "gsap"
import { useContacts } from "shared/store"
import { useDomEvent } from "shared/hooks"
import { useModal } from "shared/store"

gsap.registerPlugin(ScrollTrigger)

export function Header({ variant, theme = "default" }) {
  const [newVariant, setNewVariant] = useState(variant)
  const { phone } = useContacts()
  const { openModal, modalIsOpen, closeModal } = useModal()

  const toggleMenu = useCallback(() => {
    if (modalIsOpen) closeModal()
    else openModal(<Menu onClose={closeModal} />)
  }, [closeModal, openModal, modalIsOpen])

  const updateVariant = useCallback(() => {
    if (variant === "overlay") {
      const condition = window.scrollY > window.innerHeight - HEADER_HEIGHT
      setNewVariant(condition ? void 0 : variant)
    } else {
      setNewVariant(variant)
    }
  }, [variant])

  useDomEvent("scroll", updateVariant)
  useEffect(updateVariant)

  const variantCx = {
    landing: "md:bg-[transparent] text-brown-100",
    overlay: "bg-[transparent] text-white",
    fixed: "bg-[transparent] text-white"
  }[newVariant]

  const wrapCx = {
    landing: "sticky md:fixed top-0 left-0 right-0 z-[100]",
    fixed: "fixed top-0 left-0 right-0",
    overlay: "sticky top-0 z-[100]"
  }[newVariant]

  const themeCx = {
    default: "bg-white",
    dark: "bg-brown-100"
  }[theme]

  const logo = useRef()

  useLayoutEffect(() => {
    if (variant === "landing") {
      ScrollTrigger.refresh()
      const ctx = gsap.context(self => {
        gsap
          .timeline({
            defaults: {
              ease: "none"
            },
            scrollTrigger: {
              trigger: logo.current,
              scrub: 1,
              start: "top top",
              end: () => "+=200"
            }
          })
          .fromTo(logo.current, { scale: 1 }, { scale: 0.2 })
      }, logo)
      return () => ctx.revert()
    }
  }, [variant, newVariant])

  return (
    <div className={cx("z-[100]", wrapCx)}>
      <div className={cx("flex-none max-md:z-[10]", themeCx, variantCx)}>
        <div
          className={cx(
            "flex h-[var(--header-height-sm)] px-[16px] py-[8px] md:h-[var(--header-height-md)] md:items-center md:px-[45px] md:py-[10px] xl:h-[var(--header-height-xl)] xl:py-[15px]",
            {
              "max-md:h-[86px] max-md:items-start max-md:pt-[18px]": variant === "landing"
            }
          )}
        >
          <div className="link max-w-[350px] flex-1 max-md:hidden">
            <Link to="genplan">
              <div className="menu-text">Выбрать лот</div>
            </Link>
          </div>
          <div className="flex-1 max-md:hidden" />
          <div
            className={cx(
              "flex min-w-[16%] justify-center self-baseline md:flex-none",
              variant === "landing" ? "flex-auto" : "flex-none"
            )}
          >
            <Link to="/">
              <LogoIcon
                className={cx("block h-[54px] w-[111px] md:h-[63px] md:w-[129px] xl:h-[84px] xl:w-[171px]", {
                  hidden: newVariant === "landing"
                })}
              />
              <LogoSmIcon
                className={cx("block h-[64px] w-[226px] md:hidden", {
                  "max-md:hidden": newVariant !== "landing"
                })}
              />
            </Link>
            <div
              ref={logo}
              className={cx("absolute origin-top max-md:hidden md:py-[10px] xl:py-[20px]", {
                hidden: newVariant !== "landing"
              })}
            >
              <Link to="/">
                <LogoLandingIcon className="block md:h-[236px] md:w-[768px] xl:h-[332px] xl:w-[1080px]" />
              </Link>
            </div>
          </div>
          <div className="z-[99] flex flex-1 items-center justify-end">
            <div className="md:gap-0p-[10px] link cursor-pointer transition" onClick={toggleMenu}>
              <BurgerIcon />
            </div>
          </div>
          <div className="group flex-none text-right max-md:hidden md:w-[290px]">
            <a href={`tel:${phone}`}>
              <div className="menu-text">
                <span className="">+</span>
                {phone.slice(1)}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const HEADER_HEIGHT = 90
