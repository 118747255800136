import { useCallback, useState } from "react"

import { http } from "shared/http"

export function useForm(initialState) {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({ ...initialState })
  const [response, setResponse] = useState()
  const [error, setError] = useState({})

  const setValue = useCallback(
    (key, value) => {
      setState(state => ({
        ...state,
        [key]: value
      }))
    },
    [setState]
  )

  const reset = useCallback(async () => {
    setState({ ...initialState })
    setLoading(false)
    setResponse()
    setError({})
  }, [initialState])

  const submit = useCallback(async () => {
    try {
      setLoading(true)
      setError({})
      const response = await http.request({
        url: "/feedback",
        method: "post",
        data: state
      })
      setState({ ...initialState })
      setResponse(response.data)
    } catch (error) {
      const { errors } = error.response.data
      const finalErrors = Object.keys(errors).reduce((acc, cur) => {
        acc[cur] = errors[cur].join(",")
        return acc
      }, {})
      setError(finalErrors)
    } finally {
      setLoading(false)
    }
  }, [state, initialState])

  return {
    state,
    setValue,
    submit,
    loading,
    error,
    response,
    reset
  }
}
