import { useEffect, useRef } from "react"
import { Button } from "shared/components/navigation"
import { forceDownload } from "shared/helpers"
import { gsap } from "gsap"

export function Section1() {
  const wrapperRef = useRef()
  const maskLeft = useRef()
  const maskRight = useRef()

  useEffect(() => {
    const ctx = gsap.context(self => {
      gsap
        .timeline({
          defaults: {
            ease: "none"
          },
          scrollTrigger: {
            trigger: wrapperRef.current,
            scrub: 1,
            start: "top top",
            end: () => "+=" + wrapperRef.current.offsetHeight
          }
        })
        .add("mask")
        .fromTo(maskLeft.current, { scaleX: 1 }, { scaleX: 0 }, "mask")
        .fromTo(maskRight.current, { scaleX: 1 }, { scaleX: 0 }, "mask")
    }, wrapperRef)
    return () => ctx.revert()
  }, [])

  const isDownloading = useRef(false)
  const downloadFile = async fileId => {
    if (!isDownloading.current) {
      try {
        isDownloading.current = true
        await forceDownload(fileId)
      } finally {
        isDownloading.current = false
      }
    }
  }

  return (
    <>
      <div ref={wrapperRef} className="py-0 text-brown-100 md:pt-[260px] xl:pt-[364px]">
        <div className="relative mt-[30px] xl:mt-[55px]">
          <img alt="" src={require("../assets/h.jpg")} className="origin-top max-md:hidden" />
          <img alt="" src={require("../assets/hm.jpg")} className="w-[100%] md:hidden" />
          <div ref={maskLeft} className="absolute left-0 top-0 bottom-0 w-[45px] origin-left bg-white max-md:hidden" />
          <div
            ref={maskRight}
            className="absolute right-0 top-0 bottom-0 w-[45px] origin-right bg-white max-md:hidden"
          />
        </div>
      </div>
      <div className="wrap py-0 text-brown-100">
        <div className="h1 mt-[20px] text-center md:mt-[30px] xl:mt-[85px] xl:text-[40px]">О проекте</div>
        <div className="h2 mt-[32px] max-md:text-center md:mt-[40px] xl:mt-[86px]">
          Изысканное сочетание высококлассного городского комфорта с гармонией загородного дома
        </div>
        <div className="relative z-10 mt-9 flex justify-center">
          <Button variant="small" onClick={() => downloadFile("presentation")}>
            скачать презентацию
          </Button>
        </div>
      </div>
    </>
  )
}
