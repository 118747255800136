import { ReactComponent as BurgerIcon } from "../assets/burger.svg"
import { Itef } from "shared/components/itef"
import { Link } from "react-router-dom"
import { Menu } from "./menu"
import { ReactComponent as VkIcon } from "../assets/vk.svg"
import { sanitizeHTML } from "shared/helpers"
import { useCallback } from "react"
import { useContacts } from "shared/store"
import { useModal } from "shared/store"

export function FooterMd() {
  const { phone, address, social, workShedule } = useContacts()
  const { vk } = social
  const { openModal, modalIsOpen, closeModal } = useModal()

  const toggleMenu = useCallback(() => {
    if (modalIsOpen) closeModal()
    else openModal(<Menu onClose={closeModal} />)
  }, [closeModal, openModal, modalIsOpen])

  return (
    <div className="flex flex-col px-[16px] md:min-h-[466px] md:px-[45px]">
      <div className="flex-none">
        <div className="h-[1px] w-[100%] bg-current" />
      </div>
      <div className="grid flex-auto grid-cols-2 py-[30px]">
        <div className="border-1 flex flex flex-col border-r border-current pr-[20px]">
          <div className="flex flex-auto gap-[20px]">
            <div className="flex-auto">
              <div className="h5">
                <a className="link" href={`tel:${phone}`}>
                  {phone}
                </a>
              </div>
              <div className="h5 mt-[14px] mt-[12px]">
                <a
                  className="link"
                  href="https://yandex.ru/maps/240/togliatti/house/ulitsa_marshala_zhukova_37a/YEAYdQdjQUcPQFtpfXlxcnhqbA==/?l=sat%2Cskl&ll=49.303699%2C53.503500&utm_source=share&z=17"
                  target="_blank"
                  rel="noreferrer"
                  dangerouslySetInnerHTML={{ __html: sanitizeHTML(address.complex) }}
                />
              </div>
              <div className="mt-[14px]">
                <a
                  className="link"
                  href="https://xn--80az8a.xn--d1aqf.xn--p1ai/%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B/%D0%BA%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3-%D0%BD%D0%BE%D0%B2%D0%BE%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA/%D0%BE%D0%B1%D1%8A%D0%B5%D0%BA%D1%82/54417"
                  target="_blank"
                  rel="noreferrer"
                  >
                Проектная декларация размещена на сайте наш.дом.рф (Клубный дом "Маршал")
                </a>
              </div>
              <div className="mt-[12px]">
                <a
                  className="link"
                  href="https://xn--80az8a.xn--d1aqf.xn--p1ai/%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B/%D0%BA%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3-%D0%BD%D0%BE%D0%B2%D0%BE%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA/%D0%BE%D0%B1%D1%8A%D0%B5%D0%BA%D1%82/54418"
                  target="_blank"
                  rel="noreferrer"
                  >
                Проектная декларация размещена на сайте наш.дом.рф (г. Тольятти, ул. Маршала Жукова, д. 37А, строение 1)
                </a>
              </div>
            </div>
            <div className="flex-none text-right">
              <Link to="genplan" className="link block">
                <div className="menu-text">Выбрать лот</div>
              </Link>
              <a href="https://granirf.ru" target="_blank" rel="noreferrer" className="link mt-[8px] block">
                <div className="menu-text">Девелопер</div>
              </a>
              <a href="https://granirf.ru/documents" target="_blank" rel="noreferrer" className="link mt-[8px] block">
                <div className="menu-text">Документы</div>
              </a>
            </div>
          </div>
          <div className="flex flex-none items-end">
            <div className="flex-auto">
              <div className="menu-text">Офис продаж</div>
              <div className="menu-text mt-[10px]">{address.office}</div>
              {workShedule.length > 0 && (
                <div className="menu-text mt-[10px]">
                  {workShedule.map((item, index) => (
                    <div key={index}>{item}</div>
                  ))}
                </div>
              )}
            </div>
            <div className="flex-none">
              {vk && (
                <div className="link flex-none">
                  <a href={vk} target="_blank" rel="noreferrer">
                    <VkIcon className="h-[11px] w-[18px]" />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col pl-[20px]">
          <div className="flex flex-auto">
            <div className="flex-auto">
              <div className="max-w-[454px] font-proximanova opacity-60">
                Любая информация, представленная на данном сайте, носит исключительно информационный характер и ни при
                каких обстоятельствах не является публичной офертой в понимании положений статьи 437 Гражданского
                кодекса Российской Федерации.
              </div>
              <div className="mt-[10px]">
                <a target="_blank" href="/policy" className="link menu-text">
                  Политика конфиденциальности
                </a>
              </div>
            </div>
            <div className="flex-none">
              <div className="link" onClick={toggleMenu}>
                <BurgerIcon />
              </div>
            </div>
          </div>
          <div className="flex flex-none items-end">
            <div className="flex-auto">
              <Link to="/">
                <img
                  src={require("../assets/logo.png")}
                  alt=""
                  className="block h-[54px] w-[111px] md:h-[63px] md:w-[129px] xl:h-[84px] xl:w-[180px]"
                />
              </Link>
            </div>
            <div className="flex-none">
              <Itef />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
