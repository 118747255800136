import { ReactComponent as Map1 } from "../assets/loc1.svg"
import { ReactComponent as Map2 } from "../assets/loc2.svg"
import { ReactComponent as Map3 } from "../assets/loc3.svg"
import { ReactComponent as Map5 } from "../assets/loc5.svg"

export const location = {
  data: [
    {
      id: 0,
      value: "1",
      text: "Минута до леса",
      map: <Map1 className="block h-[auto] w-full md:h-full md:w-[auto]" />
    },
    {
      id: 1,
      value: "2",
      text: "Минуты до Lada-Парк",
      map: <Map2 className="block h-[auto] w-full md:h-full md:w-[auto]" />
    },
    {
      id: 2,
      value: "5",
      text: "Минут до Статус-холла",
      map: <Map3 className="block h-[auto] w-full md:h-full md:w-[auto]" />
    },
    {
      id: 4,
      value: "10",
      text: "Минут до набережной и пляжа",
      map: <Map5 className="block h-[auto] w-full md:h-full md:w-[auto]" />
    }
  ]
}
