import useSWR from "swr"

export const useHistory = () => {
  return useSWR({
    url: "/history"
  })
}

export const useHistoryDetail = id => {
  return useSWR({
    url: `/history/${id}`
  })
}
