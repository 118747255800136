import { cx } from "shared/helpers"

export function Button({ variant = "default", children, ...props }) {
  const variantCx = {
    default: "h-[125px] w-[279px] xl:h-[117px] xl:w-[325px]",
    small: "h-[63px] w-[143px] md:h-[90px] md:w-[227px] xl:h-[106px] xl:w-[279px]",
    form: "h-[70px] w-[186px] md:h-[90px] md:w-[227px] xl:h-[106px] xl:w-[279px]"
  }[variant]

  return (
    <button
      {...props}
      className={cx(
        "button1 group relative flex cursor-pointer items-center justify-center font-tenorsans text-[16px] md:text-[18px] xl:text-[20px]",
        variantCx
      )}
    >
      <svg className="absolute top-0 left-0 block" width="100%" height="100%" viewBox="0 0 281 107" fill="none">
        <path
          d="M177.152 17.0044C178.376 17.6181 179.906 17.4647 181.436 17.6181C193.831 18.3853 206.226 19.6126 218.315 22.5277C232.852 26.0564 247.237 30.5056 260.703 37.2562C265.446 39.5576 269.884 42.3192 273.863 45.6945C280.596 51.3712 281.82 59.1957 277.382 66.8669C275.546 70.0887 273.097 73.1572 270.19 75.4585C266.211 78.527 261.774 81.1352 257.336 83.4365C247.39 88.4995 236.831 91.5679 225.966 94.1761C191.23 102.461 155.882 105.223 124.053 105.99C97.5796 106.143 75.0852 104.609 53.0498 100.16C41.42 97.8582 30.0963 94.6364 19.9968 88.346C15.4061 85.431 10.9684 82.2091 7.29582 78.3736C-0.0493036 70.8558 -0.967443 61.8039 4.5414 52.752C6.37768 49.8369 8.52001 47.0753 10.8154 44.6205C17.0893 38.0234 24.4344 32.807 32.2386 28.3577C47.3879 19.6126 63.6084 13.6291 80.441 9.79357C111.658 2.12243 143.487 -0.332329 175.622 1.66217C193.678 2.88955 211.735 4.88404 229.486 8.56619C244.635 11.7881 259.784 15.0099 274.016 21.4537"
          stroke="currentColor"
          strokeWidth="1.7715"
        />
      </svg>
      <div className="max-md:font-tenorSans text-[12px] uppercase transition group-hover:opacity-50 md:text-[18px] xl:text-[20px]">
        {children}
      </div>
    </button>
  )
}
