export function SubmitSuccess({ message = "Мы свяжемся с вами в ближайшее время", onResetClick, variant = "default" }) {
  const hCx = {
    default: "h3",
    primary: "xl:text-[60px] md:text-[40px] text-[20px] leading-[1.3] uppercase"
  }[variant]

  const textCx = {
    default: "text2 mt-[5px] md:mt-[20px] xl:mt-[0px]",
    primary: "text-[11px] md:text-[14px] xl:text-[16px] mt-[10px] md:mt-[15px] xl:mt-[20px]"
  }[variant]

  return (
    <div className="grid max-md:text-center">
      <div className={hCx}>спасибо!</div>
      <div className={textCx}>{message}</div>
    </div>
  )
}
