import { FloorPicker } from "../components/floor-picker"
import { FloorPickerSm } from "../components/floor-picker-sm"
import { useFloor } from "../api"

export function FloorPickerWidget({
  building,
  section,
  variant,
  floor,
  isOpened = false,
  setIsOpened,
  isParking = false
}) {
  const { data: { data: { floors } = {} } = {} } = useFloor({ building, section })

  return variant === "sm" ? (
    <FloorPickerSm
      floors={floors ?? []}
      currentFloorNumber={floor}
      getFloorUrl={floor => ["/floor", building, section, floor].join("/")}
      isOpened={isOpened}
      setIsOpened={setIsOpened}
      isParking={isParking}
    />
  ) : (
    <FloorPicker
      floors={floors ?? []}
      currentFloorNumber={floor}
      getFloorUrl={floor => ["/floor", building, section, floor].join("/")}
      isParking={isParking}
    />
  )
}
