import { ErrorLabel, SubmitSuccess } from "shared/components/forms"
import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps"
import { useEffect, useMemo, useRef, useState } from "react"

import { Button } from "shared/components/navigation"
import { MetaTags } from "shared/components/seo"
import { PatternFormat } from "react-number-format"
import { ReactComponent as VkIcon } from "../assets/vk.svg"
import { calcLatLngCenter } from "../helpers"
import { useComplex } from "shared/store"
import { useContacts } from "shared/store"
import { useForm } from "shared/hooks"
import { useSeo } from "shared/hooks"

export const ContactsPage = () => {
  const { phone, email, address, social, workShedule, officeLocation } = useContacts()
  const { location } = useComplex()
  const { vk } = social
  const complexLocation = useMemo(() => (location ? location.split(",") : []), [location])
  const companyLocation = useMemo(() => (officeLocation ? officeLocation.split(",") : []), [officeLocation])
  const mapCenter = useMemo(() => {
    return calcLatLngCenter([complexLocation, companyLocation])
  }, [companyLocation, complexLocation])
  const { title, description } = useSeo()
  const formRef = useRef()
  const [form, setForm] = useState()

  useEffect(() => {
    if (form) {
      formRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [form])

  const openForm = () => {
    if (form) {
      formRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    } else setForm(true)
  }

  return (
    <>
      <div className="-mt-[var(--header-height-sm)] flex min-h-[100vh] flex-col overflow-x-hidden md:-mt-[var(--header-height-md)] xl:-mt-[var(--header-height-xl)]">
        <div className="md:min-h-50vh md:min-h-68vh relative flex-auto">
          <MetaTags title={title} description={description} />
          <div className="absolute right-0 top-0 bottom-0 left-0">
            <YMaps>
              <Map
                width="100%"
                height="100%"
                options={{ suppressMapOpenBlock: true }}
                defaultState={{ center: mapCenter, zoom: 12 }}
                modules={["geoObject.addon.balloon"]}
              >
                <Placemark
                  geometry={complexLocation}
                  options={{
                    iconLayout: "default#image",
                    iconImageSize: [56, 62],
                    iconImageOffset: [-56 / 2, -62],
                    iconImageHref: require("../assets/complex-mark.svg").default,
                    balloonPanelMaxMapArea: 0
                  }}
                  properties={{
                    balloonContentHeader: "Адрес ЖК",
                    balloonContentBody: address.complex
                  }}
                />
                <Placemark
                  geometry={companyLocation}
                  options={{
                    iconLayout: "default#image",
                    iconImageSize: [56, 62],
                    iconImageOffset: [-56 / 2, -62],
                    iconImageHref: require("../assets/company-mark.svg").default,
                    balloonPanelMaxMapArea: 0
                  }}
                  properties={{
                    balloonContentHeader: "Офис продаж",
                    balloonContentBody: address.office
                  }}
                />
              </Map>
            </YMaps>
          </div>
        </div>
        <div className="wrap flex-none pt-[30px] pb-0 md:pt-[40px] xl:pt-[50px]">
          <div className="md:grid-12">
            <div className="col-start-1 col-end-5">
              <h1 className="h1">Контакты</h1>
            </div>
            <div className="col-start-5 col-end-7">
              <div className="h4 link max-md:mt-[25px]">
                <a href="tel:phone">{phone}</a>
              </div>
              <div className="text2 mt-[8px] xl:mt-[10px]">{address.complex}</div>
            </div>
            <div className="col-start-8 col-end-11 max-md:mt-[8px]">
              <div className="text2 text-black/50">Офис продаж</div>
              <div className="text2">
                {address.office}
                {workShedule.length > 0 && (
                  <div>
                    {workShedule.map((item, index) => (
                      <div key={index}>{item}</div>
                    ))}
                  </div>
                )}
              </div>
              {email && (
                <div className="text2 mt-[8px] md:mt-0 md:hidden">
                  <a className="link" href="mailto:sales@granirf.ru">
                    {email}
                  </a>
                </div>
              )}
            </div>
            <div className="link btn col-start-11 col-end-13 cursor-pointer border-black uppercase max-md:mt-[34px] max-md:flex max-md:justify-between max-md:border-t-[1px] max-md:py-[16px]">
              <div onClick={() => openForm()}>напишите нам</div>
              <div className="md:hidden">
                {vk && (
                  <div className="link ">
                    <a href={vk} target="_blank" rel="noreferrer">
                      <VkIcon className="h-[15px] w-[30px]" />
                    </a>
                  </div>
                )}
              </div>
            </div>
            {form && <div className="h-[1px] w-full bg-black md:hidden" />}
          </div>
          <div className="md:grid-12 mt-[0] h-[50px] items-center border-t-[1px] max-md:flex max-md:hidden md:mt-[40px] md:h-[75px] md:h-[90px] xl:mt-[50px]">
            <div className="col-start-11 col-end-13 flex justify-between">
              <div>
                <a className="text2 link" href="mailto:sales@granirf.ru">
                  sales@granirf.ru
                </a>
              </div>
              <div>
                {vk && (
                  <div className="link">
                    <a href={vk} target="_blank" rel="noreferrer">
                      <VkIcon className="h-[15px] w-[30px]" />
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {form && (
        <div
          ref={formRef}
          className="wrap grid-cols-2 overflow-hidden pt-0 pb-0 md:grid md:min-h-[620px] md:pb-[25px] xl:min-h-[700px] xl:pb-[30px]"
        >
          <div className="max-md:hidden" />
          <div className="flex h-[100%] w-[100%] items-center max-md:text-center md:border-l-[1px] md:pl-[30px]">
            <Form />
          </div>
        </div>
      )}
    </>
  )
}

const Form = () => {
  const [formatState, setFormatState] = useState(false)
  const { state, setValue, submit, error, response } = useForm({
    requestType: "callback",
    phone: "",
    name: ""
  })

  return (
    <div className="w-[100%] max-md:pb-[40px]">
      {response ? (
        <SubmitSuccess />
      ) : (
        <>
          <div className="h3 max-md:hidden">Напишите нам</div>
          <div className="mt-[30px] w-[100%] items-end justify-between md:mt-[74px] md:flex xl:mt-[120px]">
            <div className="flex-auto">
              <div className="md:max-w-[290px] xl:max-w-[355px]">
                <div>
                  <input
                    placeholder="Ваше имя"
                    value={state.name}
                    className="input w-[100%]"
                    onChange={ev => setValue("name", ev.target.value)}
                  />
                  {error.name && <ErrorLabel message={error.name} />}
                </div>
                <div className="mt-[25px] md:mt-[20px]">
                  <div>
                    <PatternFormat
                      placeholder="Номер телефона"
                      value={state.phone}
                      mask="_"
                      allowEmptyFormatting={formatState && !state.phone}
                      format={`+7 (###) ###-##-##`}
                      className="input w-[100%]"
                      onChange={ev =>
                        setValue("phone", ev.target.value === "+7 (___) ___-__-__" ? "" : ev.target.value)
                      }
                      onMouseEnter={() => setFormatState(true)}
                      onMouseLeave={() => {
                        setFormatState(false)
                      }}
                    />
                  </div>
                  {error.phone && <ErrorLabel message={error.phone} />}
                </div>
                <div className="mt-[25px] md:mt-[20px]">
                  <textarea
                    placeholder="Сообщение"
                    value={state.message}
                    className="input w-[100%]"
                    onChange={ev => setValue("message", ev.target.value)}
                  />
                  {error.message && <ErrorLabel message={error.message} />}
                </div>
              </div>
            </div>
            <div className="mt-[10px] text-left text-[10px] leading-[1.2] md:hidden">
              Нажимая кнопку “Отправить”, вы даете{" "}
              <a href={`/agreement`} target="_blank" rel="noreferrer" className="opacity-50 hover:underline">
                согласие на обработку персональных данных
              </a>
              .
            </div>
            <div className="flex-none max-md:mt-[20px] max-md:flex max-md:justify-center">
              <Button variant="small" onClick={submit}>
                отправить
              </Button>
            </div>
          </div>
          <div className="mt-[70px] text-[10px] leading-[1.2] max-md:hidden lg:mt-[100px]">
            Нажимая кнопку “Отправить”, вы даете{" "}
            <a href={`/agreement`} target="_blank" rel="noreferrer" className="opacity-50 hover:underline">
              согласие на обработку персональных данных
            </a>
            .
          </div>
        </>
      )}
    </div>
  )
}
