import axios from "axios"

export { default as cx } from "classnames"

export const createPager = (el, arr) => {
  const index = arr.indexOf(el)

  const count = arr.length
  const page = index >= 0 ? index + 1 : 1

  const prev = arr[index - 1]
  const next = index !== -1 ? arr[index + 1] : void 0

  return { prev, next, page, count, curent: el }
}

export const sanitizeHTML = html => html

export const jsonSWR = (res, data) => {
  const meta = res.data?.meta
  return { ...res, data: { data, meta } }
}

export function addMockProps(WrappedComponent, props) {
  return () => <WrappedComponent {...props} />
}

export function printJSON(json) {
  const prettyJSON = JSON.stringify(json, null, 2)
  return { __html: `<div style="overflow: scroll"><pre>${prettyJSON}</pre></div>` }
}

export function declOfNum(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
}

export const Enum = obj => {
  const keysByValue = new Map()
  const EnumLookup = value => keysByValue.get(value)

  for (const key of Object.keys(obj)) {
    EnumLookup[key] = obj[key]
    keysByValue.set(EnumLookup[key], key)
  }

  return Object.freeze(EnumLookup)
}

export function copyToClipboard(
  val,
  callback = () => {
    alert("Ссылка скопирована в буфер обмена")
  }
) {
  const el = document.createElement("textarea")

  el.value = val
  document.body.appendChild(el)
  el.select()

  document.execCommand("copy")
  document.body.removeChild(el)

  callback()
}

export function imagesLoad(element, callback) {
  var allImgsLength = 0
  var allImgsLoaded = 0
  var allImgs = []

  var filtered = Array.prototype.filter.call(element.querySelectorAll("img"), function (item) {
    if (item.src === "") {
      return false
    }

    // Firefox's `complete` property will always be `true` even if the image has not been downloaded.
    // Doing it this way works in Firefox.
    var img = new Image()
    img.src = item.src
    return !img.complete
  })

  filtered.forEach(function (item) {
    allImgs.push({
      src: item.src,
      element: item
    })
  })

  allImgsLength = allImgs.length
  allImgsLoaded = 0

  // If no images found, don't bother.
  if (allImgsLength === 0) {
    callback.call(element)
  }

  allImgs.forEach(function (img) {
    var image = new Image()

    // Handle the image loading and error with the same callback.
    image.addEventListener("load", function () {
      allImgsLoaded++

      if (allImgsLoaded === allImgsLength) {
        callback.call(element)
        return false
      }
    })

    image.src = img.src
  })
}

export const forceDownload = async (fileId, filename = "download.txt") => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { complex: process.env.REACT_APP_COMPLEX_NAME }
  })
  try {
    const response = await instance.get("/documents/byurl", {
      params: { url: fileId },
      responseType: "blob"
    })

    const href = window.URL.createObjectURL(response.data)
    const link = document.createElement("a")
    link.href = href

    const disposition = response.headers["content-disposition"]
    if (disposition) {
      const matched = disposition.match(/filename="(.+)"/)
      if (matched.length === 2) {
        filename = matched[1]
      }
    }

    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(href)
  } catch (error) {
    alert("Нет данных для загрузки")
  }
}
