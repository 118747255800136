import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/lazy"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Lazy } from "swiper"
import { ReactComponent as ArrowNextIcon } from "../assets/arrow-next.svg"
import { ReactComponent as ArrowPrevIcon } from "../assets/arrow-prev.svg"
import { ReactComponent as CloseIcon } from "../assets/close.svg"
import { Link } from "react-router-dom"
import { Progress } from "shared/components/progress"
import { cx } from "shared/helpers"
import { defaults } from "lodash"
import { useState } from "react"
SwiperCore.use([Autoplay, Lazy])

export const GalleryLight = ({ files, name, closePath = "/", cover = true, swiperParams }) => {
  const [active, setActive] = useState(0)

  const options = defaults(swiperParams, {
    preloadImages: false,
    lazy: true,
    navigation: {
      prevEl: ".swiper-prev",
      nextEl: ".swiper-next"
    },
    spaceBetween: 40,
    loop: false,
    speed: 500,
    slidesPerView: 1,
    autoplay: { delay: 3000, disableOnInteraction: false },
    onSlideChange: swiper => setActive(swiper.realIndex)
  })

  return (
    <div className="h-[100vh] bg-brown-100 text-white">
      <div className="fixed top-0 left-0 right-0 bottom-0 w-[100vw] bg-brown-100 md:pl-[145px] xl:pl-[170px]">
        <Swiper className="h-[100%] w-[100%]" {...options}>
          {files.map(image => (
            <SwiperSlide key={image.hash} className="relative h-[100%]">
              <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
                <div className={cx("max-md:relative max-md:w-full md:h-full", cover ? "w-full" : "relative")}>
                  <img
                    loading={options.preloadImages ? "eager" : "lazy"}
                    className={cx("h-[100%] w-[100%]", cover ? "object-cover" : "object-contain")}
                    alt=""
                    src={image.url}
                  />
                  <div className="absolute top-0 left-0 right-0 bottom-0 bg-black/30" />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="h2 absolute bottom-[20px] z-[1] max-md:right-[16px] md:left-[50%] md:bottom-[30px] md:ml-[73px] md:translate-x-[-50%] xl:bottom-[40px]">
          {name}
        </div>
        <div className="absolute left-[16px] bottom-[16px] z-[1] text-center md:hidden">
          <Link to={closePath} className="link">
            <CloseIcon className="h-[46px] w-[46px]" />
            <div className="mt-[4px] font-proximanova text-[9px] text-white/50">Закрыть</div>
          </Link>
        </div>
      </div>
      <div className="relative flex h-full justify-center max-md:hidden md:w-[145px] md:py-[50px] xl:w-[170px]">
        <div className="flex h-full flex-col items-center">
          <div className="flex-none text-center">
            <Link to={closePath} className="link">
              <CloseIcon className="md:h-[65px] md:w-[65px] xl:h-[80px] xl:w-[80px]" />
              <div className="mt-[5px] font-proximanova text-[14px] text-white/50">Закрыть</div>
            </Link>
          </div>
          <div className="flex flex-auto items-center pb-[48px] pt-[62px]">
            <Progress list={files} active={active} variant="light" />
          </div>
          <div className="flex-none">
            <div
              className={cx("swiper-next cursor-pointer", {
                "text-white/50": active + 1 === files.length
              })}
            >
              <ArrowNextIcon />
            </div>
            <div
              className={cx("swiper-prev cursor-pointer", {
                "text-white/50": active === 0
              })}
            >
              <ArrowPrevIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
