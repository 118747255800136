export function selectBuildings(entity) {
  return entity.map(building => {
    return Object.assign({}, building, {
      number: building.number.toString(),
      sections: building.sections.map(section => {
        return Object.assign({}, section, {
          number: section.number.toString()
        })
      })
    })
  })
}
