import { Button } from "shared/components/navigation"
import { Link } from "react-router-dom"

export const AgreementPage = () => {
  return (
    <div className="wrap max-md:py-[50px]">
      <h1 className="h1">Согласие</h1>
      <div className="md:grid-12 mt-[20px] md:mt-[40px]">
        <div className="h3 md:col-start-1 md:col-end-6">
          на обработку персональных данных ПОЛЬЗОВАТЕЛЕЙ САЙТА, ПРИНАДЛЕЖАЩЕГО ОБЩЕСТВУ С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ
          СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК «ГРАНИТ»
        </div>
        <div className="text max-md:mt-[50px] md:col-start-7 md:col-end-13">
          Настоящим я, далее – «Субъект Персональных Данных», во исполнение требований Федерального закона от 27.07.2006
          г. № 152-ФЗ «О персональных данных» (с изменениями и дополнениями) свободно, своей волей и в своем интересе
          даю согласие ООО Специализированный Застройщик «ГРАНИТ», ОГРН 1136324007313, ИНН 6324041030, юридический
          адрес: 445004, РФ, Самарская область, г.Тольятти, Ореховый проезд д. 13 (далее – ООО СЗ «ГРАНИТ»), на
          обработку своих персональных данных, указанных при заполнении формы обратной связи, регистрации - на сайте
          (далее – Сайт), направляемой (заполненной) с использованием Сайта.
          <br />
          <br />
          Целью обработки моих персональных данных является: регистрация сведений физических лиц (субъектов персональных
          данных), необходимых для осуществления деятельности, по предоставлению посреднических услуг при купле-продажи
          недвижимого имущества за вознаграждение или на договорной основе.
          <br />
          <br />
          Основанием для обработки персональных данных являются:
          <Ul>
            <li>
              Ст. 24 Конституции Российской Федерации; ст.6 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных
              данных";
            </li>
            <li>Устав ООО СЗ «ГРАНИТ».</li>
          </Ul>
          <br />
          Под персональными данными я понимаю любую информацию, относящуюся ко мне как к Субъекту Персональных Данных, в
          том числе:
          <Ul>
            <li>мои фамилию, имя, отчество;</li>
            <li>год, месяц, дата и место рождения;</li>
            <li>адрес;</li>
            <li>семейное, социальное, имущественное положение;</li>
            <li>образование;</li>
            <li>профессия;</li>
            <li>доходы;</li>
            <li>
              а также ИНН, паспортные данные, данные медицинского полиса, страхового свидетельства, дополнительные
              сведения, предусмотренные условиями договора и требованиями федеральных законов, определяющих случаи и
              особенности обработки персональных данных.
            </li>
          </Ul>
          <br />
          Под обработкой персональных данных я понимаю сбор, систематизацию, накопление, хранение, уточнение
          (обновление, изменение), извлечение, использование, передачу (предоставление, доступ) третьим лицам для
          достижения целей по заключенным договорам с согласия субъекта, чьи персональные данные обрабатываются,
          обезличивание, блокирование, уничтожение персональных данных, а также иные действия с персональными данными с
          учетом действующего законодательства.
          <br />
          <br />
          Датой выдачи согласия на обработку персональных данных Субъекта Персональных Данных является дата отправки
          персональных данных, указанных при заполнении формы обратной связи, с Сайта.
          <br />
          Обработка персональных данных Субъекта Персональных Данных осуществляется способом смешенной
          (автоматизированной, неавтоматизированной) обработки (на бумажных, на электронных носителях информации и в
          ИСПДн), с передачей по внутренней сети юридического лица, без передачи в сеть общего пользования Интернет; на
          территории Самарской области, код 63.
          <br />
          ООО СЗ «ГРАНИТ» принимает необходимые правовые, организационные и технические меры или обеспечивает их
          принятие для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения,
          изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных
          неправомерных действий в отношении персональных данных, а также принимает на себя обязательство сохранения
          конфиденциальности персональных данных Субъекта Персональных Данных.
          <br />
          ООО СЗ «ГРАНИТ» вправе либо в предусмотренных законом случаях обязан передавать персональные данные для
          обработки контролирующим органам государственной власти и местного самоуправления, кредитным организациям,
          индивидуальному предпринимателю Насыртдинову Р.И. ОГРНИП 320631300116878, ООО "Регистратор доменных имен
          РЕГ.РУ" ОГРН 1067746613494 обеспечивая при этом принятие такими лицами соответствующих обязательств в части
          конфиденциальности персональных данных.
          <br />
          <br />
          Я ознакомлен(а), что:
          <br />
          настоящее согласие на обработку моих персональных данных, указанных при заполнении формы обратной связи,
          регистрации на Сайте ООО СЗ «ГРАНИТ», направляемых (заполненных) с использованием Cайта, действует в течение 5
          (пяти) лет с момента регистрации на Cайте;
          <br />
          согласие может быть отозвано мною на основании письменного заявления в произвольной форме;
          <br />
          предоставление персональных данных третьих лиц без их согласия влечет ответственность в соответствии с
          действующим законодательством Российской Федерации.
          <br />В случае отзыва субъектом персональных данных или его представителем согласия на обработку персональных
          данных ООО СЗ «ГРАНИТ» вправе продолжить обработку персональных данных без согласия субъекта персональных
          данных при наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи
          11 Федерального закона №152-ФЗ «О персональных данных» от 26.06.2006 г. Настоящее согласие действует в течение
          5 (пяти) лет, либо до момента отзыва обработки персональных данных.
          <div className="mt-[40px] md:mt-[70px] xl:mt-[90px]">
            <Link to="/policy">
              <Button>
                политика
                <br /> конфиденциальности
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

const Ul = ({ children }) => <ul className="ml-[12px] list-inside list-disc">{children}</ul>
