import { Link, useParams } from "react-router-dom"
import { PLACEMENT_STATUS, PLACEMENT_TYPE } from "shared/constans"
import { debounce, get } from "lodash"
import { prefetchPlacement, usePlacementById } from "../api"
import { useCallback, useMemo, useState } from "react"
import { useSeo, useThrowError } from "shared/hooks"

import { Button } from "shared/components/navigation"
import { Compass } from "../components/compass"
import { FloorPickerWidget } from "../components/floor-picker-widget"
import { FloorSchema } from "../components/floor-schema"
import { Gallery } from "shared/components/gallery"
import { GenplanSchema } from "../components/genplan-schema"
import { Legend } from "../components/legend"
import { MetaTags } from "shared/components/seo"
import { Modal } from "shared/components/modal"
import { PlacementLevelsSwitcher } from "../components/placement-levels-switcher"
import { PlacementPlan } from "../components/placement-plan"
import { PlacementTooltip } from "../components/placement-tooltip"
import { ReactComponent as PrintIcon } from "../assets/print.svg"
import { ReservationForm } from "../components/reservation-form"
import { ReactComponent as ShareIcon } from "../assets/share.svg"
import { copyToClipboard } from "shared/helpers"
import { useModal } from "shared/store"

export function PlacementPage() {
  const params = useParams()
  const placementId = params.id
  const [pickerSmIsOpened, setPickerSmIsOpened] = useState()

  const { openModal, closeModal } = useModal()
  const { error, data: { data, meta } = {} } = usePlacementById(placementId)
  useThrowError(error)
  const { title, description } = useSeo(meta?.seo)

  const getVal = useCallback(
    (key, defaultValue) => {
      const value = get(data?.currentFloor, key)
      return value || defaultValue
    },
    [data]
  )

  const { section, floor, ...rest } = meta ?? {}
  const building = rest?.building?.buildingId
  const placement = getVal("placement", {})
  const compasDegree = getVal("compasDegree", 0)
  const viewbox = getVal("floorPlanViewbox", "0 0 1781 838")
  const floorPlan = getVal("floorPlan")
  const placements = getVal("placements", [])
  const files = placement?.planType?.files

  const GalleryModal = () => (
    <Modal onClose={closeModal} variant="light" title="Галерея">
      <Gallery files={files} />
    </Modal>
  )

  const openGallery = e => {
    e.stopPropagation()
    e.preventDefault()
    openModal(<GalleryModal />)
  }

  const openForm = () => {
    openModal(
      <Modal onClose={closeModal} title="Заявка на квартиру">
        <ReservationForm placementUniCode={placement.uniCode} placementNumber={placement.placementNumber} />
      </Modal>
    )
  }

  const debouncedPrefetch = useMemo(() => {
    return debounce(prefetchPlacement, 100)
  }, [])

  const prefetch = useCallback(
    (ev, placement) => {
      debouncedPrefetch(placement.uniCode)
    },
    [debouncedPrefetch]
  )
  const isParking = placement.placementTypeId === PLACEMENT_TYPE.Parking

  return (
    <div className="wrap max-md: top-0 bottom-0 right-0 left-0 pt-[30px] pb-[25px] md:absolute md:pt-[75px] md:pb-[50px] xl:pt-[80px] xl:pb-[45px]">
      <MetaTags title={title} description={description} />
      <div className="md:grid-12 h-[100%]">
        <div className="col-start-1 col-end-4 overflow-hidden md:h-[100%]">
          <PlacementTooltip
            placement={placement}
            buttons={
              <Buttons
                apartmentUi={placement.uniCode}
                variant="head"
                onClick={openForm}
                isSold={placement.status === PLACEMENT_STATUS.Sold}
              />
            }
          >
            {placement.status !== PLACEMENT_STATUS.Sold && (
              <>
                <div className="max-md:hidden">
                  <Button variant="small" onClick={openForm}>
                    заброни&shy;ровать
                  </Button>
                </div>
              </>
            )}
          </PlacementTooltip>
        </div>
        <div className="col-start-4 col-end-11 max-md:mt-[30px] md:flex md:gap-[40px] xl:pb-[35px]">
          <div className="w-full flex-auto md:flex md:h-[100%] md:flex-col">
            <div className="relative flex-auto md:mx-[-20px]">
              <PlacementPlan
                planType={placement.planType}
                onGalleryButtonClick={openGallery}
                showGalleryButton={false}
              />
            </div>
            {placement.levels && (
              <div className="flex-none pt-[30px]">
                <PlacementLevelsSwitcher levels={placement.levels} />
              </div>
            )}
          </div>
          <div className="flex flex-none items-center justify-between max-md:mt-[20px] md:justify-end md:self-end">
            <Compass deg={compasDegree} />
            <Link to={`/floor/${building}/${section}/${floor}`} className="btn link md:hidden">
              план этажа
            </Link>
          </div>
        </div>
        <div className="col-start-11 col-end-13 flex flex-col md:items-end xl:pb-[35px]">
          <div className="flex-auto flex-col gap-[30px] md:flex md:max-w-[200px]">
            <div className="flex-auto max-md:flex max-md:items-center">
              <div className="flex-auto text-center">
                <div className="max-md:hidden">
                  <div className="number md:h-[100px] md:leading-[100px] xl:h-[150px] xl:leading-[150px]">
                    {isParking ? "P" : floor}
                  </div>
                  <Link to={`/floor/${building}/${section}/${floor}`} className="link block">
                    <div className="btn mt-[6px] xl:mt-[25px]">Этаж</div>
                  </Link>
                </div>
                <div
                  onClick={() => {
                    setPickerSmIsOpened(true)
                  }}
                  className="md:hidden"
                >
                  <div className="number">{isParking ? "P" : floor}</div>
                  <div className="text-[20px] md:mt-[6px] xl:mt-[25px]">Этаж</div>
                  <div className="mt-[5px] text-[10px] leading-[1.5]">Для выбора этажа нажмите на него.</div>
                </div>
                <div className="mt-[30px] max-md:hidden md:min-h-[134px] xl:mt-[25px]">
                  <FloorSchema
                    viewbox={viewbox}
                    schema={floorPlan}
                    placements={placements}
                    scalable={true}
                    onMouseEnter={prefetch}
                    placementUniCode={placement.uniCode}
                  />
                </div>
                <div className="mt-[30px] md:hidden">
                  <div className="mb-[20px]">
                    <Legend />
                  </div>
                  <FloorSchema
                    viewbox={viewbox}
                    schema={floorPlan}
                    placements={placements}
                    placementUniCode={placement.uniCode}
                    variant="sm"
                  />
                </div>
              </div>
            </div>
            <div className="flex-none text-center max-md:mt-[30px] max-md:hidden">
              <div className="btn mb-[15px] max-md:hidden xl:mb-[25px]">
                <Link className="link" to="/genplan">
                  Генплан
                </Link>
              </div>
              <div className="md:h-[14vw] md:max-h-[200px]">
                <GenplanSchema selectedBuildings={{ [+building]: [+section] }} />
              </div>
            </div>
          </div>
          <div className="md:hidden">
            <FloorPickerWidget
              building={building}
              section={section}
              variant="sm"
              floor={floor}
              isOpened={pickerSmIsOpened}
              setIsOpened={setPickerSmIsOpened}
              isParking={isParking}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const Buttons = ({ apartmentUi, onClick, isSold }) => {
  const { openModal, closeModal } = useModal()
  return (
    <>
      <div className="flex justify-between gap-[30px] max-xl:flex-col max-md:hidden">
        <Link to={`/placement/${apartmentUi}/print`} className="link block">
          <PrintIcon />
        </Link>
        <div
          onClick={() => {
            copyToClipboard(window.location, () => {
              openModal(
                <Modal onClose={closeModal} variant="alert">
                  ссылка скопирована
                  <br /> в буфер обмена
                </Modal>
              )
            })
          }}
          className="link cursor-pointer"
        >
          <ShareIcon />
        </div>
      </div>
      <div className="flex items-center justify-between gap-[20px] md:hidden">
        <Link to={`/placement/${apartmentUi}/print`} className="link block">
          <PrintIcon />
        </Link>
        {!isSold && (
          <Button onClick={onClick} style={{ width: 174, height: 65 }}>
            заброни&shy;ровать
          </Button>
        )}
        <div
          onClick={() => {
            copyToClipboard(window.location, () => {
              openModal(
                <Modal onClose={closeModal} variant="alert">
                  ссылка скопирована
                  <br /> в буфер обмена
                </Modal>
              )
            })
          }}
          className="link cursor-pointer"
        >
          <ShareIcon />
        </div>
      </div>
    </>
  )
}
