import create from "zustand"
import { getSearchFilterSettings } from "../api"
import shallow from "zustand/shallow"
import { xor } from "lodash"

const filterStore = create((set, get) => ({
  error: null,
  ready: false,
  page: 1,
  count: 999,
  rooms: [],
  floors: { min: 0, max: 0 },
  square: { min: 0, max: 0 },
  cost: { min: 0, max: 0 },

  toggleValue: (key, value) => {
    if (checkAlowed(key)) {
      set(state => ({ [key]: xor(state[key], [value]) }))
    }
  },

  setValue: (key, value) => {
    if (checkAlowed(key)) {
      set(() => ({ [key]: value }))
    }
  },

  initialize: async () => {
    if (!get().ready) {
      try {
        const { data } = await getSearchFilterSettings()
        set({
          ready: true,
          floors: { min: data.minFloor, max: data.maxFloor },
          square: { min: data.minSquare, max: data.maxSquare },
          cost: { min: data.minCost, max: data.maxCost }
        })
      } catch (error) {
        set({ error })
      }
    }
  }
}))

export const useSearchFilterStore = () => {
  return filterStore(
    state => ({
      selected: {
        page: state.page,
        count: state.count,
        rooms: state.rooms,
        floors: state.floors,
        square: state.square,
        cost: state.cost
      },
      ready: state.ready,
      initialize: state.initialize,
      setValue: state.setValue,
      toggleValue: state.toggleValue
    }),
    shallow
  )
}

const ALOWED_VALUES_KEYS = ["floors", "square", "cost", "rooms"]

const checkAlowed = key => {
  if (!ALOWED_VALUES_KEYS.includes(key)) {
    throw Error("Write not alowed")
  }
  return true
}
