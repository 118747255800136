export function Section8() {
  return (
    <div className="bg-white md:pb-[100px]">
      <div className="px-[16px] pt-[40px] text-[26px] leading-[118%] text-brown-100 md:px-[45px] md:pt-[90px] md:text-[68px] xl:pt-[100px] xl:text-[90px]">
        “Наш проект сочетает в себе открытость с защищенностью. Он распахнут на лесной пейзаж и восход солнца, и в то же
        время прикрыт от рутины и прозы мегаполиса.”
      </div>
      <div className="mt-[20px] grid-cols-2 gap-[20px] text-brown-100 md:mt-[20px] md:grid xl:mt-[50px]">
        <div />
        <div className="flex items-center gap-[30px] max-md:px-[16px]">
          <div className="h-[92px] w-[92px] flex-none md:h-[150px] md:w-[150px] xl:h-[200px] xl:w-[200px]">
            <img alt="" src={require("../assets/avatar.jpg")} className="h-full w-full object-cover" />
          </div>
          <div className="flex-auto">
            <div className="h1">Александр Колоярский</div>
            <div className="text max-md:hidden md:mt-[10px] xl:mt-[12px]">
              Архитектор проекта, член правления тольяттинского отделения Союза архитекторов России
            </div>
          </div>
        </div>
        <div className="text2 mt-[10px] px-[16px] text-brown-200 md:hidden">
          Архитектор проекта, член правления тольяттинского отделения Союза архитекторов России
        </div>
      </div>
    </div>
  )
}
