import { useEffect } from "react"

export function useThrowError(error, message = "Response not found") {
  useEffect(() => {
    if (error) {
      const e = new Error(message)
      e.code = "ENOENT"
      throw e
    }
  }, [error, message])
}
