import create from "zustand"
import { getSettings } from "./api"
import shallow from "zustand/shallow"

const settings = create(set => ({
  contacts: {
    phone: "",
    email: "",
    address: {
      complex: "",
      office: ""
    },
    social: {},
    workShedule: [],
    officeLocation: ""
  },
  seo: {
    title: "",
    description: ""
  },
  complex: {
    location: "",
    topViewUrl: "",
    finishDate: ""
  },
  load: async () => {
    const data = (await getSettings()).data
    const complex = data.addressComplex || ""
    const office = data.addressOffice || ""
    const workShedule = data.workSchedule || []
    const phone = data.phone || ""
    const email = data.email || ""
    const social = data.social || {}
    const title = data.defaultMeta?.title || ""
    const description = data.defaultMeta?.description || ""
    const location = data.location || ""
    const officeLocation = data.locationOffice || ""
    const topViewUrl = data.topViewUrl || ""
    const finishDate = data.finishDate || ""
    set({
      contacts: {
        phone,
        email,
        address: {
          complex,
          office
        },
        social,
        workShedule,
        officeLocation
      },
      seo: {
        title,
        description
      },
      complex: {
        location,
        topViewUrl,
        finishDate
      }
    })
  }
}))

const modal = create(set => ({
  component: null,
  close: () => set({ component: null }),
  open: component => set({ component })
}))

export const useSettings = () => {
  return settings(
    state => ({
      contacts: {
        phone: state.phone,
        address: state.address,
        social: state.social
      },
      seo: {
        title: state.title,
        description: state.description
      },
      loadSettings: state.load
    }),
    shallow
  )
}

export const useContacts = () => {
  return settings(
    state => ({
      phone: state.contacts.phone,
      email: state.contacts.email,
      address: state.contacts.address,
      social: state.contacts.social,
      workShedule: state.contacts.workShedule,
      officeLocation: state.contacts.officeLocation
    }),
    shallow
  )
}

export const useComplex = () => {
  return settings(
    state => ({
      location: state.complex.location,
      topViewUrl: state.complex.topViewUrl,
      finishDate: state.complex.finishDate
    }),
    shallow
  )
}

export const useSeoData = () => {
  return settings(
    state => ({
      title: state.seo.title,
      description: state.seo.description
    }),
    shallow
  )
}

export const useModal = () => {
  return modal(
    state => ({
      modalComponent: state.component,
      modalIsOpen: state.component !== null,
      openModal: state.open,
      closeModal: state.close
    }),
    shallow
  )
}
