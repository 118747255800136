export const improvement = {
  harmony: [
    {
      id: 0,
      src: require("../assets/ls1.jpg")
    },
    {
      id: 1,
      src: require("../assets/ls2.jpg")
    },
    {
      id: 2,
      src: require("../assets/ls3.jpg")
    },
    {
      id: 3,
      src: require("../assets/ls4.jpg")
    },
    {
      id: 4,
      src: require("../assets/ls5.jpg")
    }
  ]
}
