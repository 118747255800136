import { useEffect, useState } from "react"

export const useViewbox = (placement, variant) => {
  const [viewbox, setViewbox] = useState({ x: 0, y: 0, f: 0 })

  useEffect(() => {
    const calc = (function calc() {
      // TODO: этаже виртуальный DOM, тут нельзя делать такие манипуляции
      const s = document.querySelector(`[data-name='schema-${variant}']`),
        p = document.querySelector(`[data-polygon="${placement.uniCode}-${variant}"]`)
      // eof TODO
      if (s && p) {
        const bounder = e => e.getBoundingClientRect(),
          [sb, pb] = [s, p].map(bounder),
          x = pb.left - sb.left + pb.width / 2,
          y = pb.top - sb.top + pb.height / 2,
          // TODO: пока картинка с полями
          // считать фактор будем из вьюбокса svg
          v = s.viewBox?.baseVal,
          f = sb.width / v?.width || 1
        setViewbox({ x, y, f: f || 1 })
      }
      return calc
    })()
    requestAnimationFrame(calc)
    window.addEventListener("resize", calc)
    return () => window.removeEventListener("resize", calc)
  }, [placement, variant])

  return viewbox
}
