import useSWR from "swr"

export const useGenplan = () => {
  return useSWR({
    url: "/genplan"
  })
}

export const useGenplanMarks = () => {
  return useSWR({
    url: "/genplan/marks"
  })
}
