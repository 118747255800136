export const parsePlacemarkGeometry = placemark => {
  let geometry

  try {
    const geoJSON = JSON.parse(placemark)
    geometry = geoJSON.geometry.coordinates[0].map(([c1, c2]) => [c2, c1])
  } catch (error) {
    console.error("Placemark geometry parse error: " + error) // eslint-disable-line
  }

  return geometry
}

export const parsePlacemarkPosition = placemark => {
  let position = null

  try {
    const geoJSON = JSON.parse(placemark)
    position = geoJSON.geometry.coordinates
    position = [position[1], position[0]]
  } catch (error) {
    console.error("Placemark position parse error: " + error) // eslint-disable-line
  }

  return position
}
