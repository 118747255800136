import { cx } from "shared/helpers"
import { parsePlacemarkPosition } from "../helpers"

export function PlacemarkMarker({ placemark }) {
  const position = parsePlacemarkPosition(placemark.poly_genplan)

  const { width, height, icon_url } = placemark?.mark_type || {}

  return (
    <foreignObject x={position[1]} y={position[0]} width="300" height={height} className="pointer-events-none">
      <div data-xmlns="http://www.w3.org/1999/xhtml">
        <div className={cx("group flex w-[fit-content] gap-[10px]", { "flex-row-reverse": placemark.right })}>
          <div className="h-[40px] w-[40px]">
            <a
              href={placemark.link ? placemark.link : null}
              target="_blank"
              rel="noreferrer"
              className="pointer-events-auto"
            >
              <img src={icon_url} width={width} height={height} alt="" />
            </a>
          </div>
          <div
            className={cx(
              "pointer-events-none h-full bg-brown-100/80 p-[10px] text-[16px] uppercase leading-[19px] text-white opacity-0 transition delay-100 duration-500 group-hover:translate-x-[0px] group-hover:opacity-100",
              placemark.right ? "translate-x-[10px]" : "translate-x-[-10px]"
            )}
          >
            {placemark.name}
          </div>
        </div>
      </div>
    </foreignObject>
  )
}
