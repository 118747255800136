import { PLACEMENT_STATUS } from "shared/constans"
import { Status } from "./status"

export function Legend() {
  return (
    <div className="flex justify-center gap-[15px] md:gap-[50px]">
      <Status statusId={PLACEMENT_STATUS.Sale} />
      <Status statusId={PLACEMENT_STATUS.Booked} />
      <Status statusId={PLACEMENT_STATUS.Sold} />
    </div>
  )
}
