import { useEffect, useRef } from "react"
import { Section8 } from "../components/section8"
import { gsap } from "gsap"

export function Section2() {
  const wrapperRef = useRef()
  const img1 = useRef()
  const img2 = useRef()
  const img3 = useRef()
  const video = useRef()

  useEffect(() => {
    const ctx = gsap.context(self => {
      gsap
        .timeline({
          defaults: {
            ease: "none"
          },
          scrollTrigger: {
            trigger: wrapperRef.current,
            pin: true,
            scrub: true,
            start: "top top",
            end: () => "+=" + 4.16 * window.innerWidth
          }
        })
        .add("start")
        .fromTo(img1.current, { y: "20%" }, { y: "-20%", duration: 2 }, "start")
        .fromTo(img3.current, { y: "-20%" }, { y: "20%", duration: 2 }, "start")
        .to(img2.current, {
          duration: 1,
          scale: 1
        })
        .to(video.current, {
          duration: 2,
          opacity: 1
        })
        .to(video.current, {
          delay: 1,
          yPercent: -150,
          duration: 10
        })
    }, wrapperRef)
    return () => ctx.revert()
  }, [])

  return (
    <div className="md:h-[290vw]">
      <div className="max-md:wrap max-md:py-0 md:h-[120vh]">
        <div ref={wrapperRef} className="max-md:hidden md:mt-[-5%] md:h-[100vh]">
          <div className="md:wrap w-full max-md:mt-[30px] max-md:aspect-[1830/968] md:h-[100vh] md:py-0">
            <div className="flex h-full items-center justify-between md:gap-[44px] xl:gap-[70px]">
              <div>
                <div ref={img1} className="aspect-[597/730] will-change-transform">
                  <img className="h-full w-full object-cover" alt="" src={require("../assets/h1.jpg")} />
                </div>
              </div>
              <div>
                <div className="aspect-[496/730] overflow-hidden">
                  <img
                    ref={img2}
                    className="h-full w-full object-cover will-change-transform"
                    alt=""
                    src={require("../assets/h2.jpg")}
                  />
                </div>
              </div>
              <div>
                <div ref={img3} className="aspect-[597/730]">
                  <img
                    className="h-full w-full object-cover will-change-transform"
                    alt=""
                    src={require("../assets/h3.jpg")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div ref={video} className="absolute top-0 left-0 right-0 opacity-0">
            <img src={require("../assets/video.jpg")} className=" h-screen w-full object-cover" alt="" />
            <Section8 />
          </div>
        </div>
        <div className="mt-[30px] grid grid-cols-2 gap-[8px] md:hidden">
          <div className="grid gap-[15px]">
            <div className="aspect-[140/170]">
              <img className="h-full w-full object-cover" alt="" src={require("../assets/h1.jpg")} />
            </div>
            <div className="aspect-[140/170]">
              <img className="h-full w-full object-cover" alt="" src={require("../assets/h3.jpg")} />
            </div>
          </div>
          <div className="flex items-center">
            <div className="aspect-[140/170]">
              <img className="h-full w-full object-cover" alt="" src={require("../assets/h2.jpg")} />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[40px] pb-[40px] md:hidden">
        <img className="block w-full" alt="" src={require("../assets/videom.jpg")} />
        <Section8 />
      </div>
    </div>
  )
}
