import { AnimatePresence, motion } from "framer-motion"
import { ReactComponent as CloseIcon } from "../assets/close.svg"

import create from "zustand"
import { persist } from "zustand/middleware"

export function CookieInformer() {
  const isOpen = useStore(state => state.isOpen)
  const close = () => useStore.setState({ isOpen: false })

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed left-0 bottom-[18px] right-0 z-10 mx-auto w-[100%] max-w-[290px] text-white">
          <motion.div
            variants={motion1}
            initial="initial"
            animate="animate"
            exit="exit"
            className="absolute left-0 right-0 bottom-0 top-0 origin-bottom rounded-[50px] bg-brown-100"
          />
          <motion.div
            variants={motion2}
            initial="initial"
            animate="animate"
            exit="exit"
            className="relative z-[1] flex h-[90px] items-center px-[20px]"
          >
            <div className="flex-1 font-proximanova text-[12px] uppercase">Мы используем файлы cookie.</div>
            <button onClick={close} title="Закрыть" aria-label="Закрыть">
              <CloseIcon className="h-[28px] w-[28px]" />
            </button>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}

const useStore = create(
  persist(
    () => ({
      isOpen: true
    }),
    { name: "cookie-storage" }
  )
)

const motion1 = {
  initial: {
    scaleY: 0
  },
  animate: {
    scaleY: 1,
    transition: {
      delay: 0.5
    }
  },
  exit: {
    scaleY: 0,
    transition: {
      delay: 0.5
    }
  }
}

const motion2 = {
  initial: {
    opacity: 0,
    y: "100%"
  },
  animate: {
    opacity: 1,
    y: "0",
    transition: {
      delay: 1
    }
  },
  exit: {
    opacity: 0,
    y: "100%"
  }
}
