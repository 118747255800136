export function calcLatLngCenter(latLngs) {
  const count = latLngs.length
  const sum = latLngs.reduce(
    (acc, cur) => {
      return {
        lat: Number(acc.lat) + Number(cur[0]),
        lng: Number(acc.lng) + Number(cur[1])
      }
    },
    { lat: 0, lng: 0 }
  )
  return [sum.lat / count, sum.lng / count]
}
