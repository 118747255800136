import { PLACEMENT_STATUS_NAME, PLACEMENT_TYPE_GENETIVE_NAME } from "shared/constans"
import { NumericFormat } from "react-number-format"
import { declOfNum } from "shared/helpers"

export function PlacementTooltip({ placement = {}, buttons, children }) {
  const { placementNumber, status, rooms, square, cost, placementTypeId } = placement

  return (
    <div className="flex flex-col items-center">
      <div className="w-[100%] md:w-[fit-content] md:min-w-[250px] xl:min-w-[310px]">
        <div className="text-center">
          <div className="h1 leading-[60px]">{PLACEMENT_STATUS_NAME[status]}</div>
          {placementNumber && (
            <div className="number mt-[20px] max-md:leading-[72px] md:mt-[25px] md:leading-[98px] xl:mt-[70px] xl:leading-[156px]">
              {placementNumber}
            </div>
          )}
          <div className="text mt-[5px] lowercase text-black/50 xl:mt-[10px]">
            № {PLACEMENT_TYPE_GENETIVE_NAME[placementTypeId]}
          </div>
        </div>
        <div className="my-[15px] h-[1px] w-[100%]  bg-marsh-100 xl:my-[25px]" />
        <div className="flex justify-center">
          <div className="md:width-[fit-content] grid gap-[7px] md:gap-[2px] xl:gap-[10px]">
            {rooms > 0 && <Row label={declOfNum(rooms, ["спальня", "спальни", "спален"])}>{rooms}</Row>}
            {square > 0 && <Row label="м<sup>2</sup>">{square}</Row>}
            {cost > 0 && (
              <Row label=" ₽">
                <NumericFormat value={cost} displayType={"text"} thousandSeparator={" "} />
              </Row>
            )}
          </div>
        </div>
      </div>
      <div className="max-md:justify-self mt-[18px] items-center gap-[17px] md:mt-[28px] md:flex md:gap-[20px] xl:mt-[48px] xl:gap-[25px]">
        {children && <div className="flex-auto">{children}</div>}
        {buttons && <div className="flex-none">{buttons}</div>}
      </div>
    </div>
  )
}

const Row = ({ children, label }) => (
  <div className="flex items-center gap-[15px]">
    <div className="text-[30px] md:text-[25px] xl:text-[30px]">{children}</div>
    <div className="text2 text-black/50" dangerouslySetInnerHTML={{ __html: label }} />
  </div>
)
