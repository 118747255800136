import "assets/styles.css"

import { App } from "./app"
import React from "react"
import ReactDOM from "react-dom/client"
import { RouterProvider } from "react-router-dom"
import { http } from "shared/http"
import { router } from "router"

http.interceptors.request.use(
  config => {
    const complex = process.env.REACT_APP_COMPLEX_NAME
    config.url = config.url.replace(/\/+$/, "")
    config.headers.complex = complex
    return config
  },
  error => Promise.reject(error)
)

http.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error)
)

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App>
      <RouterProvider router={router} />
    </App>
  </React.StrictMode>
)
