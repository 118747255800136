import { Button } from "shared/components/navigation"
import { Link } from "react-router-dom"

export function Section9() {
  return (
    <>
      <div className="py-[40px] px-[16px] text-[26px] leading-[118%] text-brown-100 md:px-[45px] md:py-[100px] md:text-[46px] xl:py-[130px] xl:text-[70px]">
        “Мы расширяем границы Вашего пребывания. Строим не просто лоты, а целый комплекс с прилегающей территорией и
        ландшафтом. Приобщайтесь к природе и наслаждайтесь её гармонией и спокойствием.”
      </div>
      <div className="relative flex aspect-[320/585] items-center justify-center text-center text-white md:aspect-[1920/1025]">
        <img
          alt=""
          src={require("../assets/l1.jpg")}
          className="absolute top-0 left-0 right-0 bottom-0 h-full w-full object-cover"
        />
        <div className="relative">
          <div className="desc-num">Только</div>
          <div className="number mt-[17px] max-md:text-[100px] md:mt-[10px] xl:mt-[30px]">25</div>
          <div className="desc-num mt-[12px] md:mt-[10px] xl:mt-[30px]">лотов</div>
          <div className="mt-[82px] md:mt-[60px] xl:mt-[116px]">
            <Link to="genplan">
              <Button variant="small">выбрать лот</Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
