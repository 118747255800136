import { ErrorLabel, SubmitSuccess } from "shared/components/forms"
import { Button } from "shared/components/navigation"
import { PatternFormat } from "react-number-format"
import { useForm } from "shared/hooks"
import { useState } from "react"

export function ReservationForm({ placementUniCode, placementNumber }) {
  const [formatState, setFormatState] = useState(false)
  const { state, setValue, submit, error, response } = useForm({
    requestType: "rezerv",
    flatuid: placementUniCode,
    phone: "",
    name: ""
  })

  return (
    <div className="text-center max-md:mt-[16px]">
      {response ? (
        <div className="flex items-center max-md:min-h-[300px]">
          <SubmitSuccess variant="primary" />
        </div>
      ) : (
        <>
          <div className="number">{placementNumber}</div>
          <div className="mt-[px] md:mt-[40px] md:min-w-[484px] xl:mt-[90px] xl:min-w-[600px]">
            <div>
              <input
                placeholder="Имя"
                value={state.name}
                className="input white w-[100%]"
                onChange={ev => setValue("name", ev.target.value)}
              />
              {error.name && <ErrorLabel message={error.name} />}
            </div>
            <div className="mt-[25px] md:mt-[20px]">
              <PatternFormat
                placeholder="Tелефон"
                value={state.phone}
                mask="_"
                allowEmptyFormatting={formatState}
                format={`+7 (###) ###-##-##`}
                className="input white w-[100%]"
                onChange={ev => setValue("phone", ev.target.value === "+7 (___) ___-__-__" ? "" : ev.target.value)}
                onMouseEnter={() => setFormatState(true)}
                onMouseLeave={() => {
                  setFormatState(false)
                }}
              />
              {error.phone && <ErrorLabel message={error.phone} />}
            </div>
            <div className="mt-[10px] text-left text-[10px] leading-[1.2]">
              Нажимая кнопку “Оставить заявку”, вы даете{" "}
              <a href={`/agreement`} target="_blank" rel="noreferrer" className="opacity-50 hover:underline">
                согласие на обработку персональных данных
              </a>
              .
            </div>
            <div className="mt-[35px] flex justify-center md:mt-[80px] xl:mt-[110px]">
              <Button variant="form" onClick={submit}>
                Оставить заявку
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
