import { Button } from "shared/components/navigation"
import { Footer } from "shared/components/layout"
import { Link } from "react-router-dom"

export function Section11() {
  return (
    <div className="relative bg-footer bg-cover text-white max-md:mt-[30px]">
      <div className="flex aspect-[320/585] items-center justify-center text-center md:aspect-[1920/969]">
        <div className="relative">
          <div className="h2">Готовые пространства для ваших идей</div>
          <div className="mt-[130px] md:mt-[95px]">
            <Link to="genplan" className="inline-block">
              <Button variant="small">выбрать лот</Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="relative max-md:hidden">
        <Footer />
      </div>
    </div>
  )
}
