export const finish = {
  slides: [
    {
      id: 0,
      name: "комфорт",
      src: require("../assets/f1.jpg"),
      text: "Клубный дом на 25 лотов всего по три на этаже – это исключительный комфорт Вашей жизни. Безупречная шумоизоляция межквартирных стен и перекрытий. Грузопассажирский скоростной лифт Premium сегмента с изолированной от стен жилых помещений шахтой."
    },
    {
      id: 1,
      name: "технологии",
      src: require("../assets/f2.jpg"),
      text: "Архитектурная подсветка фасада подчеркивает уникальность проекта. Все коммуникации максимально скрыты от глаз, включая наружные блоки кондиционеров в специальных нишах на фасаде. Толщина внешних стен 190 мм и вентилируемый фасад с утеплителем 120 мм. Отдельная линия водопровода с фильтрованной питьевой водой."
    },
    {
      id: 2,
      name: "территория",
      src: require("../assets/f3.jpg"),
      text: "Закрытая территория с круглосуточной охраной, видеонаблюдением и системой контроля доступа. Комфортная безбарьерная среда. Зелёный двор с однокаскадным водопадом, беседками, качелями, шезлонгами и зоной барбекю. Озеленение из множества видов декоративных растений и деревьев."
    },
    {
      id: 3,
      name: "пространства",
      src: require("../assets/f4.jpg"),
      text: "Дизайнерское лобби с аквариумом для отдыха и общения на первом этаже. Консьерж-сервис, система автоматической передачи показаний приборов учета в Сервисную компанию. Комната для хранения колясок, велосипедов, спортивного инвентаря, электросамокатов с возможностью подзарядки. Санузел для домашних питомцев на первом этаже. Две входные группы."
    },
    {
      id: 4,
      name: "паркинг",
      src: require("../assets/f5.jpg"),
      text: "Крытая автопарковка на 28 мест, оснащенная современными осветительными приборами и доступной навигацией для легкого ориентирования."
    },
    {
      id: 5,
      name: "лоты",
      src: require("../assets/f6.jpg"),
      text: "Лоты с мастер-спальнями, террасами, гардеробными, кладовыми и бельевыми комнатами. Умные входные двери с электронными замками класса premium. Высота потолков - 3 м. Внутрипольные конвекторы перед витражами. В каждом лоте по 2-3 санузла."
    },
    {
      id: 6,
      name: "окна",
      src: require("../assets/f7.jpg"),
      text: "Энергоэффективные алюминиевые окна Alutech с мультифункциональными 2-х камерными стеклопакетами с энергосбережением. Наивысший коэффициент звукоизоляции. Детские замки безопасности и приточные клапаны. Французские балконы. Панорамный вид из гостиных на лес обеспечен витражными или раздвижными окнами в пол."
    }
  ]
}
