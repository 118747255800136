import { ReactComponent as BottomIcon } from "../assets/bottom.svg"
import { Button } from "shared/components/navigation"
import { ReactComponent as ErrorIcon } from "../assets/error.svg"
import { Link } from "react-router-dom"
import { ReactComponent as TopIcon } from "../assets/top.svg"

export function NotFound() {
  return (
    <div className="fixed left-0 right-0 top-0 bottom-0 flex min-h-screen items-center px-[16px] py-[50px] max-md:w-[100%] max-md:min-w-[288px] md:justify-center md:px-[45px]">
      <img
        alt=""
        src={require("../assets/bg.jpg")}
        className="absolute top-0 bottom-0 left-0 right-0 block h-[100%] w-[100%] object-cover"
      />
      <div className="relative flex h-[100%] flex-col">
        <div className="w-[100%] flex-none">
          <TopIcon className="h-[auto] w-[100%]" />
        </div>
        <div className="grid h-[100%] flex-auto items-center text-center text-white md:grid-cols-2">
          <div className="flex justify-center">
            <ErrorIcon className="block md:h-[43px] md:w-[108px] xl:h-[94px] xl:w-[356px]" />
          </div>
          <div className="flex justify-center">
            <Link to="/">
              <Button variant="small">на главную</Button>
            </Link>
          </div>
        </div>
        <div className="w-[100%] flex-none">
          <BottomIcon className="h-[auto] w-[100%]" />
        </div>
      </div>
    </div>
  )
}
