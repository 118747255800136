import { PLACEMENT_STATUS_COLOR, PLACEMENT_STATUS_NAME, PLACEMENT_TYPE_GENETIVE_NAME } from "shared/constans"
import { ReactComponent as CircleIcon } from "../assets/circle.svg"
import { ReactComponent as CircleOutlineIcon } from "../assets/circle-outline.svg"
import { NumericFormat } from "react-number-format"
import { declOfNum } from "shared/helpers"

export function PlacementTooltipLight({ placement = {}, children }) {
  const { placementNumber, status, rooms, square, cost, placementTypeId } = placement

  return (
    <div>
      <div className="text-[20px] leading-[28px] md:leading-[24px]">{PLACEMENT_STATUS_NAME[status]}</div>
      <div className="mt-[10px]">
        {placementNumber && (
          <div className="flex items-center justify-between gap-[5px]">
            <Row label={`№ ${PLACEMENT_TYPE_GENETIVE_NAME[placementTypeId]}`}>{placementNumber}</Row>
            <div>
              {status === 0 ? (
                <CircleOutlineIcon
                  className="h-[12px] w-[12px] md:h-[14px] md:w-[14px]"
                  style={{ color: PLACEMENT_STATUS_COLOR[status] }}
                />
              ) : (
                <CircleIcon
                  className="h-[12px] w-[12px] md:h-[14px] md:w-[14px]"
                  style={{ color: PLACEMENT_STATUS_COLOR[status] }}
                />
              )}
            </div>
          </div>
        )}
        <div className="mt-[7px] mb-[5px] h-[1px] w-[100%] bg-marsh-100" />
        <div className="flex justify-between gap-[5px]">
          {rooms > 0 && <Row label={declOfNum(rooms, ["спальня", "спальни", "спален"])}>{rooms}</Row>}
          <div className="md:hidden">{square > 0 && <Row label="м<sup>2</sup>">{square}</Row>}</div>
        </div>
        {square > 0 && (
          <div className="mt-[10px] max-md:hidden">
            <Row label="м<sup>2</sup>">{square}</Row>
          </div>
        )}
        {cost > 0 && (
          <div className="mt-[10px]">
            <Row label="₽">
              <NumericFormat value={cost} displayType={"text"} thousandSeparator={" "} />
            </Row>
          </div>
        )}
      </div>
      {children && <div className="mt-[10px]">{children}</div>}
    </div>
  )
}

const Row = ({ children, label }) => (
  <div className="flex items-baseline gap-[10px]">
    <div className="text-[25px]">{children}</div>
    <div className="text-[14px] lowercase text-black/50" dangerouslySetInnerHTML={{ __html: label }} />
  </div>
)
