import { Enum } from "shared/helpers"

const SALE = 0
const SOLD = 1
const BOOKED = 2

const BUILDING_SOON = "soon"
const BUILDING_SALE = "on_sale"
const BUILDING_SOLD = "sold"

const APARTMENT = "apartment"
const PARKING = "parking"
const COMMERCIAL = "commer"
const STORAGE = "storage"
const HOUSE = "storage"

export const PLACEMENT_STATUS = Enum({
  Sale: SALE,
  Sold: SOLD,
  Booked: BOOKED
})

export const PLACEMENT_STATUS_COLOR = Enum({
  [SALE]: "#E5CEBE",
  [SOLD]: "rgba(238, 238, 238, 0.9)",
  [BOOKED]: "#E5CEBE"
})

export const PLACEMENT_STATUS_NAME = Enum({
  [SALE]: "В продаже",
  [SOLD]: "Продано",
  [BOOKED]: "Забронировано"
})

export const BUILDING_STATUS = Enum({
  Soon: BUILDING_SOON,
  Sale: BUILDING_SALE,
  Sold: BUILDING_SOLD
})

export const BUILDING_STATUS_COLOR = Enum({
  [BUILDING_SALE]: "#E41170",
  [BUILDING_SOLD]: "#CBCBCB",
  [BUILDING_SOON]: "#F5F5F5"
})

export const BUILDING_STATUS_NAME = Enum({
  [BUILDING_SOON]: "Ведётся строительство",
  [BUILDING_SALE]: "В продаже",
  [BUILDING_SOLD]: "Квартиры проданы"
})

export const PLACEMENT_TYPE = Enum({
  Apartment: APARTMENT,
  Parking: PARKING,
  Commercial: COMMERCIAL,
  Storage: STORAGE
})

export const BUILDING_TYPE = Enum({
  House: HOUSE,
  Parking: PARKING
})

export const PLACEMENT_TYPE_NAME = Enum({
  [APARTMENT]: "Лот",
  [PARKING]: "Машиноместо",
  [COMMERCIAL]: "Коммерческое помещение",
  [STORAGE]: "Кладовая"
})

export const PLACEMENT_TYPE_GENETIVE_NAME = Enum({
  [APARTMENT]: "лота",
  [PARKING]: "машиноместа",
  [COMMERCIAL]: "помещения",
  [STORAGE]: "кладовой"
})

export const PLACEMENT_TYPE_SHORT_NAME = Enum({
  [APARTMENT]: "лот.",
  [PARKING]: "мест",
  [COMMERCIAL]: "пом.",
  [STORAGE]: "пом."
})
