import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/mousewheel"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { EffectFade, Mousewheel } from "swiper"

import { ReactComponent as ArrowNextIcon } from "../assets/arrow-next.svg"
import { ReactComponent as ArrowPrevIcon } from "../assets/arrow-prev.svg"
import { Button } from "shared/components/navigation"
import { Link } from "react-router-dom"
import { Progress } from "shared/components/progress"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { cx } from "shared/helpers"
import { gsap } from "gsap"
import { useGallery } from "../api"
import { useState } from "react"
import { useThrowError } from "shared/hooks"

gsap.registerPlugin(ScrollTrigger)

SwiperCore.use([EffectFade, Mousewheel])

export const GalleryPage = () => {
  const { error, data: { data = [] } = {} } = useGallery()
  useThrowError(error)

  const [active, setActive] = useState(0)
  const [swiper, setSwiper] = useState()

  let params = {
    direction: "vertical",
    effect: "fade",
    loop: false,
    speed: 500,
    slidesPerView: 1,
    navigation: {
      prevEl: ".swiper-prev",
      nextEl: ".swiper-next"
    },
    onSlideChange: swiper => {
      setActive(swiper.realIndex)
    }
  }

  return (
    <div className="-mt-[var(--header-height-sm)] flex bg-brown-100 max-md:flex-col-reverse md:-mt-[var(--header-height-md)] md:h-[100vh] xl:-mt-[var(--header-height-xl)]">
      <div className="box-border flex flex-[50%] flex-col p-[16px] max-md:min-h-[50vh] md:px-[45px] md:pt-[102px] md:pb-[30px] xl:pt-[140px] xl:pb-[40px]">
        <div className="h2 flex-none text-white">галерея</div>
        <div className="flex-auto md:mb-[30px] md:overflow-y-auto">
          <div className="mt-[32px] flex gap-[25px] md:mt-[30px] xl:mt-[60px]">
            <div className="max-md:hidden">
              <Progress list={data} active={active} variant="light" fixed={true} />
            </div>
            <div className="md:hidden">
              <Progress list={data} active={active} variant="light" fixed={true} sm={true} />
            </div>
            <div className="flex flex-col">
              {data.map((item, index) => (
                <div
                  key={item.id}
                  className={cx(
                    "h4 flex h-[28px] cursor-pointer items-center transition hover:opacity-50 md:h-[45px]",
                    index === active ? "text-white" : "text-white/50"
                  )}
                  onClick={() => swiper.slideTo(index)}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="relative flex flex-none justify-center max-md:hidden">
          <div className="absolute bottom-0 left-0 flex flex-none flex-col text-white">
            <div
              className={cx("swiper-prev cursor-pointer", {
                "text-white/50": active === 0
              })}
            >
              <ArrowPrevIcon />
            </div>
            <div
              className={cx("swiper-next mt-[4px] cursor-pointer", {
                "text-white/50": active + 1 === data.length
              })}
            >
              <ArrowNextIcon />
            </div>
          </div>
          {data[active] && (
            <div className="text-white">
              <Link to={`/gallery/${data[active].id}`}>
                <Button variant="small">Смотреть</Button>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="relative flex-[50%] max-md:h-[50vh] md:h-full">
        <Swiper {...params} className="h-full w-full max-md:h-[50vh]" onSwiper={setSwiper}>
          {data.map(item => (
            <SwiperSlide key={item.id} className="h-full w-full">
              <Link to={`/gallery/${item.id}`}>
                <img alt="" src={item.coverUrl} className="block h-full w-full object-cover" />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
        {data[active] && (
          <div className="absolute right-[16px] bottom-[16px] z-[1] text-white md:hidden">
            <Link to={`/gallery/${data[active].id}`}>
              <Button variant="small">Смотреть</Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
