import { memo } from "react"
import { motion } from "framer-motion"

export const Compass = memo(
  function ({ deg = 0 }) {
    return (
      <motion.div className="relative inline-block select-none" animate={{ rotate: deg }}>
        <img src={require("../assets/compass.svg").default} className="block" alt="" />
        <motion.div
          initial={{ x: "-50%" }}
          animate={{ rotate: deg * -1, x: "-50%" }}
          className="absolute -top-4 left-[50%] text-[14px] uppercase"
        >
          c
        </motion.div>
      </motion.div>
    )
  },
  (prev, next) => !(next.deg > 0)
)
