import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/controller"
import "swiper/css/lazy"

import { Swiper, SwiperSlide } from "swiper/react"
import { useEffect, useRef, useState } from "react"

import { ReactComponent as ArrowNextIcon } from "../assets/arrow-next.svg"
import { ReactComponent as ArrowPrevIcon } from "../assets/arrow-prev.svg"
import { Progress } from "shared/components/progress"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SliderSm } from "./slider-sm"
import { cx } from "shared/helpers"
import { gsap } from "gsap"

const harmony = [
  {
    id: 0,
    src: require("../assets/ls1.jpg")
  },
  {
    id: 1,
    src: require("../assets/ls2.jpg")
  },
  {
    id: 2,
    src: require("../assets/ls3.jpg")
  },
  {
    id: 3,
    src: require("../assets/ls4.jpg")
  },
  {
    id: 4,
    src: require("../assets/ls5.jpg")
  },
  {
    id: 5,
    src: require("../assets/ls6.jpg")
  },
  {
    id: 6,
    src: require("../assets/ls7.jpg")
  },
  {
    id: 7,
    src: require("../assets/ls8.jpg")
  },
  {
    id: 8,
    src: require("../assets/ls9.jpg")
  },
  {
    id: 9,
    src: require("../assets/ls10.jpg")
  }
]
const harmonySm = [
  {
    id: 0,
    src: require("../assets/lsm1.jpg")
  },
  {
    id: 1,
    src: require("../assets/lsm2.jpg")
  },
  {
    id: 2,
    src: require("../assets/lsm3.jpg")
  },
  {
    id: 3,
    src: require("../assets/lsm4.jpg")
  },
  {
    id: 4,
    src: require("../assets/lsm5.jpg")
  },
  {
    id: 5,
    src: require("../assets/lsm6.jpg")
  },
  {
    id: 6,
    src: require("../assets/lsm7.jpg")
  },
  {
    id: 7,
    src: require("../assets/lsm8.jpg")
  },
  {
    id: 8,
    src: require("../assets/lsm9.jpg")
  },
  {
    id: 9,
    src: require("../assets/lsm10.jpg")
  }
]

export const Section5 = ({ onClick }) => {
  const wrapperRef = useRef()
  const inner = useRef()
  const [swiper, setSwiper] = useState()
  const [active, setActive] = useState(0)

  useEffect(() => {
    const ctx = gsap.context(self => {
      ScrollTrigger.matchMedia({
        "(min-width: 960px)": function () {
          gsap.timeline({
            defaults: {
              ease: "none"
            },
            scrollTrigger: {
              trigger: wrapperRef.current,
              anticipatePin: 1,
              pin: true,
              scrub: 0.5,
              start: "top top",
              end: "+=2000",
              toggleActions: "play none play none",
              onUpdate(ev) {
                if (swiper && swiper.slides) {
                  let progress = ev.progress
                  let count = swiper.slides.length * 2
                  let index = Math.round(count * progress)
                  swiper.slideTo(index)
                }
              }
            }
          })

          gsap
            .timeline({
              defaults: {
                ease: "none"
              },
              scrollTrigger: {
                trigger: inner.current,
                scrub: true,
                start: "top bottom",
                end: () => "+=" + 2 * inner.current.offsetHeight
              }
            })
            .fromTo(
              inner.current,
              { y: -inner.current.offsetHeight },
              {
                y: 0,
                ease: "none"
              }
            )
        }
      })
    }, wrapperRef)
    return () => ctx.revert()
  }, [swiper])

  let params = {
    preloadImages: false,
    lazy: true,
    navigation: {
      prevEl: ".swiper-prev",
      nextEl: ".swiper-next"
    },
    effect: "fade",
    loop: false,
    speed: 500,
    slidesPerView: 1,
    onSlideChange: swiper => setActive(swiper.realIndex)
  }

  return (
    <div className="md:h-[2000px]">
      <div id="section5" ref={wrapperRef} className="overflow-hidden bg-white max-md:hidden">
        <div className="h-[100vh] grid-cols-2 gap-[20px] will-change-transform max-md:mt-[30px] md:grid" ref={inner}>
          <div className="items-center gap-[6px] px-[16px] text-brown-100 md:flex md:pl-[45px] md:pr-0">
            <div className="flex-auto">
              <div className="h2 xl:min-w-[790px]">
                Расширяем границы
                <br /> в гармонии с<br /> природой
              </div>
              <div className="text mt-[30px] md:mt-[60px] md:w-[521px] xl:mt-[40px] xl:w-[668px]">
                Закрытый внутренний двор на эксплуатируемой кровле паркинга с ландшафтным озеленением и благоустроенными
                местами отдыха для жителей вдохновлен естественным окружением. Обустроенный ландшафт, правильное
                зонирование и продуманная территория – это качество Вашей жизни.
              </div>
            </div>
            <div className="flex h-full flex-none flex-col items-center gap-[30px] py-[40px]">
              <div className="min-h-[160px] flex-none">
                <div className="btn translate-y-[80px] rotate-[270deg] cursor-pointer text-brown-100" onClick={onClick}>
                  пропустить
                </div>
              </div>
              <div className="flex-auto">
                <Progress list={harmony} active={active} variant="browndark" />
              </div>
              <div className="flex-none">
                <div
                  className={cx("swiper-next mt-[4px] cursor-pointer", {
                    "text-white/50": active + 1 === harmony.length
                  })}
                >
                  <ArrowNextIcon />
                </div>
                <div
                  className={cx("swiper-prev cursor-pointer", {
                    "text-white/50": active === 0
                  })}
                >
                  <ArrowPrevIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden">
            <div className="flex h-[100vh] w-full">
              <Swiper {...params} className="h-full w-full" onSwiper={setSwiper}>
                {harmony.map(({ src, id }) => (
                  <SwiperSlide key={id} className="h-full w-full">
                    <img src={src} alt="" className="h-full w-full object-cover" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <div className="grid-cols-2 gap-[20px] max-md:mt-[30px] md:grid">
          <div className="items-center gap-[6px] px-[16px] text-brown-100 md:flex md:pl-[45px] md:pr-0">
            <div className="flex-auto">
              <div className="h2">Расширяем границы в гармонии с природой</div>
              <div className="text mt-[30px] md:mt-[60px] xl:mt-[40px]">
                Закрытый внутренний двор на эксплуатируемой кровле паркинга с ландшафтным озеленением и благоустроенными
                местами отдыха для жителей вдохновлен естественным окружением. Обустроенный ландшафт, правильное
                зонирование и продуманная территория – это качество Вашей жизни.
              </div>
            </div>
          </div>
          <div className="overflow-hidden max-md:mt-[30px]">
            <div className="aspect-[320/585] ">
              <SliderSm slides={harmonySm} variant="high" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
