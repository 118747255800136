export const spaces = {
  lobby: [
    {
      id: 0,
      src: require("../assets/s1.jpg")
    },
    {
      id: 1,
      src: require("../assets/s2.jpg")
    },
    {
      id: 2,
      src: require("../assets/s3.jpg")
    }
  ],
  receptionist: [
    {
      id: 0,
      src: require("../assets/s4.jpg")
    },
    {
      id: 1,
      src: require("../assets/s5.jpg")
    },
    {
      id: 2,
      src: require("../assets/s6.jpg")
    }
  ]
}
