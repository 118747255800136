import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import useSWR from "swr"
import { useSeoData } from "../store"
import { useThrowError } from "./use-throw-error"

export function useSeo(defaultSeo = {}) {
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const location = useLocation()
  const { title: storeTitle, description: storeDescription } = useSeoData()
  const { error, data: { data = {} } = {} } = useSWR(`/complex/settings/seodata?url=${location.pathname}`)
  useThrowError(error)

  useEffect(() => {
    setTitle(data?.meta_title || defaultSeo.title || storeTitle)
    setDescription(data?.meta_description || defaultSeo.description || storeDescription)
  }, [location, defaultSeo, data, storeTitle, storeDescription])

  return {
    title,
    description
  }
}
