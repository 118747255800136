import { AnimatePresence, motion } from "framer-motion"

import { Link } from "react-router-dom"
import { cx } from "shared/helpers"
import { useOutsideClick } from "shared/hooks"
import { useRef } from "react"

export function FloorPickerSm({
  floors,
  currentFloorNumber,
  getFloorUrl,
  isOpened,
  setIsOpened = () => {},
  isParking
}) {
  const ref = useRef()

  useOutsideClick(ref, () => {
    setIsOpened(false)
  })

  return (
    <div>
      <AnimatePresence mode="wait">
        {isOpened && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ default: { ease: "easeOut", duration: 0.5 } }}
            className="fixed top-0 left-0 right-0 bottom-0 z-[10] bg-black/70"
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {isOpened && (
          <motion.div
            ref={ref}
            initial={{ height: 0 }}
            animate={{ height: "76vh" }}
            exit={{ height: 0 }}
            transition={{ default: { ease: "easeOut", duration: 0.5 } }}
            className="fixed bottom-0 left-0 right-0 z-[11] flex h-[76vh] flex-col rounded-t-[12px] bg-white py-[30px] px-[16px]"
          >
            <div className="flex flex-none items-center text-[16px] leading-[1.5]">
              <div className="w-[40px]">Этаж</div>
            </div>
            <div className="mt-[7px] mb-[45px] h-[1px] w-[100%] flex-none bg-marsh-100" />
            <div className="flex-auto overflow-y-auto text-black/50">
              {floors.map((floor, index) => {
                const floorNumber = floor.floor
                const href = getFloorUrl(floorNumber)
                const isSelected = floorNumber === currentFloorNumber
                return (
                  <div
                    key={floorNumber}
                    onClick={() => {
                      setIsOpened(false)
                    }}
                  >
                    <Link to={href} className={cx("group flex items-center", { "text-black": isSelected })}>
                      <div className="w-[6px] flex-none">
                        {index % 2 === 0 && (
                          <div className="h-[6px] w-[6px] rounded-[50%] border-[1.5px] border-current" />
                        )}
                      </div>
                      <div className="flex-none px-[10px]">
                        <div className="h-[32px] w-[3px] bg-current" />
                      </div>
                      <div className="w-[6px] flex-none">
                        {index % 2 === 1 && (
                          <div className="h-[6px] w-[6px] rounded-[50%] border-[1.5px] border-current" />
                        )}
                      </div>
                      <div className="h4 pl-[24px]">{isParking ? "P" : floorNumber} этаж</div>
                    </Link>
                  </div>
                )
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
