import { useEffect, useRef, useState } from "react"
import { Footer } from "shared/components/layout"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Section1 } from "../components/section1"
import { Section10 } from "../components/section10"
import { Section11 } from "../components/section11"
import { Section2 } from "../components/section2"
import { Section3 } from "../components/section3"
import { Section4 } from "../components/section4"
import { Section5 } from "../components/section5"
import { Section6 } from "../components/section6"
import { Section7 } from "../components/section7"
import { Section9 } from "../components/section9"
import { gsap } from "gsap"
import { imagesLoad } from "../helpers"

const ScrollSmoother = require("shared/lib/smooth-scroll").default

gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

export const LandingPage = () => {
  const [smoother, setSmoother] = useState()
  const containerRef = useRef()

  useEffect(() => {
    const smoother = ScrollSmoother.create({
      smoothTouch: 0.1,
      effects: true,
      smooth: 1
    })
    if (window.matchMedia("(max-width: 480px)").matches) {
      smoother.kill()
      ScrollTrigger.killAll()
    }
    setSmoother(smoother)
    imagesLoad(containerRef.current, () => {
      ScrollTrigger.refresh()
    })
  }, [])

  const scrollToSection = () => {
    smoother.scrollTo("#section6", true)
  }
  return (
    <div id="smooth-wrapper" ref={containerRef}>
      <div id="smooth-content">
        <Section1 />
        <Section2 />

        <Section3 />
        <Section4 />
        <Section5 onClick={scrollToSection} />
        <div id="section6">
          <Section6 />
        </div>
        <Section7 />
        <Section9 />
        <Section10 />
        <Section11 />
        <div className="bg-marsh-200 text-white md:hidden">
          <Footer />
        </div>
      </div>
    </div>
  )
}
