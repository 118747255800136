import { FooterMd } from "./footer-md"
import { FooterSm } from "./footer-sm"

export function Footer({ sm = true, md = true, variant = "default" }) {
  return (
    <>
      {md && (
        <div className="hidden md:block">
          <FooterMd />
        </div>
      )}
      {sm && (
        <div className="block md:hidden">
          <FooterSm variant={variant} />
        </div>
      )}
    </>
  )
}
