import { GalleryLight } from "shared/components/gallery"
import { useHistoryDetail } from "../api"
import { useParams } from "react-router-dom"
import { useThrowError } from "shared/hooks"

export const HistoryDetailPage = () => {
  const params = useParams()
  const id = params.id
  const { error, data: { data = [] } = {} } = useHistoryDetail(id)
  useThrowError(error)

  const files = data?.photos || []

  return <GalleryLight files={files} name={data.name} closePath="/history" />
}
