import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/controller"
import "swiper/css/lazy"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Controller, EffectFade, Lazy, Navigation } from "swiper"
import { ReactComponent as ArrowNextIcon } from "../assets/arrow-next.svg"
import { ReactComponent as ArrowPrevIcon } from "../assets/arrow-prev.svg"
import { Progress } from "shared/components/progress"
import { cx } from "shared/helpers"
import { useState } from "react"

SwiperCore.use([Autoplay, EffectFade, Navigation, Lazy, Controller])

export function Gallery({ files, fullscreen = false, autoplay = false }) {
  const [active, setActive] = useState(0)

  let params = {
    preloadImages: false,
    lazy: true,
    navigation: {
      prevEl: ".swiper-prev",
      nextEl: ".swiper-next"
    },
    effect: "fade",
    loop: false,
    speed: 500,
    slidesPerView: 1,
    autoplay: autoplay
      ? {
          delay: 3000,
          disableOnInteraction: false
        }
      : false,
    onSlideChange: swiper => setActive(swiper.realIndex)
  }

  return (
    <>
      <div className="max-md:hidden md:flex md:h-[100%] md:max-h-screen md:flex-col md:gap-[40px] xl:gap-[75px]">
        <div className="flex-auto pt-[70px]">
          <div className="grid h-[100%] grid-cols-6 items-center gap-[20px]">
            <div className="relative col-start-1 col-end-2 flex h-full flex-col md:gap-[20px] xl:gap-[100px]">
              <div className="relative flex-auto">
                <div className="h-full pl-[10px]">
                  <Progress list={files} active={active} />
                </div>
              </div>
              <div className="flex flex-none flex-col">
                <div
                  className={cx("swiper-prev cursor-pointer", {
                    "text-black/50": active === 0
                  })}
                >
                  <ArrowPrevIcon />
                </div>
                <div
                  className={cx("swiper-next mt-[4px] cursor-pointer", {
                    "text-black": active + 1 === files.length
                  })}
                >
                  <ArrowNextIcon />
                </div>
              </div>
            </div>
            <div className="col-start-2 col-end-6 h-[100%]">
              <Swiper
                className={cx("h-[100%]", { "top-0 left-0 right-0 bottom-0 z-[1]": fullscreen })}
                style={{ position: fullscreen ? "fixed" : "relative" }}
                {...params}
              >
                {files.map(image => (
                  <SwiperSlide key={image.hash} className="relative h-[100%]">
                    <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-white">
                      <img
                        loading="lazy"
                        className={cx("h-[100%] w-[100%]", fullscreen ? "object-cover" : "object-contain")}
                        alt=""
                        src={image.url}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[20px] grid gap-[30px] md:hidden">
        {files.map(image => (
          <div key={image.hash}>
            <img loading="lazy" className="block" alt="" src={image.url} />
          </div>
        ))}
      </div>
    </>
  )
}
