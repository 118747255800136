import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/mousewheel"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { EffectFade } from "swiper"
import { useEffect, useRef, useState } from "react"

import { Button } from "shared/components/navigation"
import { Link } from "react-router-dom"
import { MonthPicker } from "../components/month-picker"
import { Progress } from "shared/components/progress"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { gsap } from "gsap"
import { useComplex } from "shared/store"
import { useHistory } from "../api"
import { useThrowError } from "shared/hooks"

gsap.registerPlugin(ScrollTrigger)

SwiperCore.use([EffectFade])

export const HistoryPage = () => {
  const { error, data: { data = [] } = {} } = useHistory()
  useThrowError(error)

  const { finishDate } = useComplex()
  const [active, setActive] = useState(0)
  const [pickerIsOpened, setPickerIsOpened] = useState(false)
  const [swiper, setSwiper] = useState()
  const container = useRef()

  useEffect(() => {
    const ctx = gsap.context(self => {
      ScrollTrigger.matchMedia({
        "(min-width: 960px)": function () {
          gsap.timeline({
            defaults: {
              ease: "none"
            },
            scrollTrigger: {
              trigger: container.current,
              anticipatePin: 1,
              pin: true,
              scrub: 0.5,
              start: "top top",
              end: "+=2000",
              toggleActions: "play none play none",
              onUpdate(ev) {
                if (swiper && swiper.slides) {
                  let progress = ev.progress
                  let count = swiper.slides.length * 2
                  let index = Math.round(count * progress)
                  swiper.slideTo(index)
                }
              }
            }
          })
        }
      })
    }, container)
    return () => ctx.revert()
  }, [swiper])

  let params = {
    direction: "vertical",
    effect: "fade",
    loop: false,
    speed: 500,
    slidesPerView: 1,
    onSlideChange: swiper => {
      setActive(swiper.realIndex)
    }
  }

  return (
    <div>
      <div className="flex min-h-[100vh] flex-col items-center justify-center bg-historysm bg-cover md:bg-history">
        <h2 className="h2 text-white max-md:text-center">
          Ход
          <br className="md:hidden" /> строительства
        </h2>
      </div>
      <div className="relative h-[100vh] pb-[30px] max-md:hidden md:pb-[70px] xl:pb-[90px]" ref={container}>
        {finishDate && (
          <div className="absolute left-[45px] md:top-[60px] xl:top-[80px]">
            <h4 className="h4">
              Срок передачи объекта -<br /> {finishDate}
            </h4>
          </div>
        )}
        {data.length > 0 && (
          <Swiper {...params} className="h-[100%]" onSwiper={setSwiper}>
            {data.map(item => (
              <SwiperSlide key={item.id} className="grid h-full grid-cols-2">
                <div className="wrap flex h-[100%] items-end md:pb-[60px] md:pt-[60px] xl:pb-[40px] xl:pt-[80px]">
                  <div className="w-full bg-brown-100">
                    <div className="flex items-center gap-[30px] xl:gap-[40px]">
                      <div className="desc-num mt-[10px] xl:mt-[20px]">{item.name}</div>
                      <div>
                        <Link to={`/history/${item.id}`}>
                          <Button variant="small">галерея</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <img alt="" src={item.coverUrl} className="block h-full w-full object-cover" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        <div className="absolute right-[50%] z-[1] flex items-center md:top-[60px] md:bottom-[126px] md:mr-[50px] xl:top-[132px] xl:bottom-[220px] xl:mr-[75px]">
          <Progress list={data} active={active} variant="brown" />
        </div>
      </div>
      {data.length > 0 && (
        <div className="bg-brown-100 md:hidden">
          <div className="wrap pb-[48px]">
            {finishDate && (
              <h4 className="h4">
                Срок передачи объекта -<br /> {finishDate}
              </h4>
            )}
            <div className="mt-[18px] text-[10px] text-white/50">Для выбора месяца, нажмите на него</div>
            <div className="mt-[30px] flex items-center justify-between">
              <div
                className="desc-num mt-[10px]"
                onClick={() => {
                  setPickerIsOpened(true)
                }}
              >
                {data[active].name}
              </div>
              <div>
                <Link to={`/history/${data[active].id}`}>
                  <Button variant="small">галерея</Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="aspect-1 mb-[30px]">
            <img alt="" src={require("../assets/h.jpg")} className="block h-full w-full object-cover" />
          </div>
        </div>
      )}
      <div className="md:hidden">
        <MonthPicker
          active={active}
          months={data}
          isOpened={pickerIsOpened}
          onClose={() => {
            setPickerIsOpened(false)
          }}
          onSelect={item => {
            setActive(item)
          }}
        />
      </div>
    </div>
  )
}
