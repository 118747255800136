import { cx, declOfNum } from "shared/helpers"
import { useSeo, useThrowError } from "shared/hooks"
import { Compass } from "../components/compass"
import { FloorSchema } from "../components/floor-schema"
import { GenplanSchema } from "../components/genplan-schema"
import { MetaTags } from "shared/components/seo"
import { NumericFormat } from "react-number-format"
import { PLACEMENT_TYPE } from "shared/constans"
import { PrintHeader } from "../components/print-header"
import { Status } from "../components/status"
import { get } from "lodash"
import { useCallback } from "react"
import { useParams } from "react-router-dom"
import { usePlacementById } from "../api"

export function PrintPlacementPage() {
  const params = useParams()
  const placementId = params.id

  const { error, data: { data, meta } = {} } = usePlacementById(placementId, { print: true })
  useThrowError(error)
  const { title, description } = useSeo(meta?.seo)

  const getVal = useCallback(
    (key, defaultValue) => {
      const value = get(data?.currentFloor, key)
      return value || defaultValue
    },
    [data]
  )

  const placement = getVal("placement", {})
  const placements = getVal("placements", [])
  const compasDegree = getVal("compasDegree", 0)
  const floor = getVal("placement.floor")
  const building = getVal("placement.buildingId")
  const section = getVal("placement.section")
  const viewbox = getVal("floorPlanViewbox", "0 0 1781 838")
  const floorPlan = getVal("floorPlan")
  const render = placement.planType?.renderPlanUrl ?? placement.planType?.renderSchemeUrl
  const { placementNumber, status, rooms, square, cost, levels, placementTypeId } = placement

  const isParking = placementTypeId === PLACEMENT_TYPE.Parking

  return (
    <div className="mx-[auto] grid min-h-[100vh] max-w-[210mm] grid-rows-[auto_1fr_auto]">
      <MetaTags title={title} description={description} />
      <PrintHeader />
      <div className="flex h-[100%] flex-col pb-[10px] pt-[20px]">
        <div className="grid min-h-[350px] flex-auto grid-cols-2 items-center gap-[20px]">
          <div className="relative h-[100%]">
            <img
              className="absolute top-0 left-0 left-0 right-0 block h-[100%] w-[100%] object-contain"
              alt=""
              src={render}
            />
          </div>
          <div className="justify-self-center">
            <Status statusId={status} variant="print" />
            {placementNumber && (
              <div className={cx("mt-[20px] mb-[15px] flex items-baseline gap-[10px]")}>
                <div className={cx("text-[70px] font-medium leading-[1]")}>{placementNumber}</div>
                <Label>№ квартиры</Label>
              </div>
            )}
            <div className={cx("grid gap-[10px]")}>
              {rooms > 0 && (
                <div className="flex items-baseline gap-[10px]">
                  <Value>{rooms}</Value>
                  <Label>
                    {declOfNum(rooms, ["Комната", "Комнаты", "Комнат"])}
                    {levels && (
                      <>
                        {" "}
                        -<span className="ml-[10px] inline-block text-pink-100">двухуровневая</span>
                      </>
                    )}
                  </Label>
                </div>
              )}
              {square && (
                <div className="flex items-baseline gap-[10px]">
                  <Value>{square}</Value>
                  <Label>
                    м<sup>2</sup>
                  </Label>
                </div>
              )}
              {cost && (
                <div className="flex items-baseline gap-[10px]">
                  <Value>
                    <NumericFormat value={cost} displayType={"text"} thousandSeparator={" "} />
                  </Value>
                  <Label>₽</Label>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-none justify-center py-[15px]">
          <Compass deg={compasDegree} />
        </div>
        <div className="grid flex-none grid-cols-2 items-end gap-[20px]">
          <div>
            <div className="flex items-baseline gap-[10px]">
              <div className="font-tenorsans text-[30px] font-semibold leading-[1.5]">{isParking ? "P" : floor}</div>
              <div>Этаж</div>
            </div>
            <div className="mt-[20px] px-[20px]">
              <FloorSchema
                viewbox={viewbox}
                schema={floorPlan}
                placements={placements}
                placementUniCode={placement.uniCode}
                variant="print"
              />
            </div>
          </div>
          <div className="px-[20px]">
            <GenplanSchema selectedBuildings={{ [+building]: [+section] }} scalable={false} />
          </div>
        </div>
      </div>
    </div>
  )
}

const Value = ({ children }) => <div className={cx("font-tenorsans text-[30px] font-semibold")}>{children}</div>

const Label = ({ children }) => <div className={cx("text-[12px] font-medium text-gray-500")}>{children}</div>
