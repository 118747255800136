export const genplan = {
  meta: {
    seo: {
      title: "Выбрать квартиру в ЖК ЖК Алтын Яр, Казань на генплане",
      description: "Выбрать квартиру в ЖК ЖК Алтын Яр, Казань на генплане"
    }
  },
  data: [
    {
      id: 1,
      name: "Дом №1",
      number: "1",
      status: "on_sale",
      building_type_id: "house",
      alter_code: null,
      mark_genplan:
        '{"type":"Feature","properties":[],"geometry":{"type":"Point","coordinates":[19.863281,57.421294]}}',
      poly_genplan:
        '{"type":"Feature","properties":[],"geometry":{"type":"Polygon","coordinates":[[[-10.59082,52.61639],[-10.415039,51.944265],[-8.525391,52.025459],[-8.129883,49.267805],[-7.250977,48.69096],[-6.723633,48.487486],[-6.020508,48.429201],[-5.888672,48.283193],[5.668945,49.325122],[5.756836,49.75288],[8.217773,49.837982],[9.536133,49.582226],[9.799805,49.809632],[13.31543,50.092393],[13.535156,50.819818],[13.579102,51.672555],[15.600586,51.781436],[15.996094,50.903033],[18.544922,51.013755],[18.676758,51.971346],[20.258789,52.079506],[25.004883,52.268157],[28.256836,52.402419],[28.388672,52.935397],[30.761719,53.041213],[30.893555,52.802761],[32.34375,52.935397],[32.519531,53.304621],[38.056641,54.033586],[39.902344,54.213861],[40.12207,54.110943],[41.616211,54.188155],[42.319336,54.367759],[42.670898,54.572062],[42.84668,54.977614],[43.066406,55.279115],[44.516602,60.586967],[44.560547,60.844911],[42.055664,62.512318],[41.000977,62.471724],[40.253906,63.035039],[39.594727,63.035039],[39.814453,63.763065],[38.056641,65.164579],[37.177734,65.146115],[35.332031,66.530768],[34.804688,66.687784],[34.189453,66.774586],[33.574219,66.791909],[32.871094,66.791909],[27.817383,66.565747],[27.817383,66.124962],[24.65332,66.035873],[23.994141,62.552857],[24.125977,62.308794],[24.213867,61.731526],[24.697266,60.930432],[23.90625,60.909073],[23.90625,60.565379],[21.665039,60.500525],[21.708984,60.196156],[19.6875,60.152442],[19.599609,59.198439],[18.061523,59.130863],[17.973633,58.904646],[15.117188,58.790978],[15.117188,58.608334],[12.65625,58.539595],[12.480469,58.722599],[3.999023,58.447733],[4.086914,58.12432],[-0.263672,57.961503],[-0.439453,58.217025],[-2.988281,58.12432],[-3.251953,58.859224],[0.219727,58.950008],[0,64.148952],[-0.571289,65.42173],[-10.151367,65.127638],[-9.975586,64.529548],[-11.469727,64.529548],[-11.25,63.470145],[-11.865234,63.43086],[-12.612305,62.734601],[-12.612305,60.802064],[-11.645508,55.72711],[-10.59082,52.61639]]]}}',
      finish_date: "IV кв. 2023",
      info: null,
      flats_available: 299,
      sections: [
        {
          id: 1,
          name: "1",
          number: 1,
          is_hidden: false,
          display: null,
          poly_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Polygon","coordinates":[[[24.125977,62.206512],[23.994141,62.573106],[24.609375,66.018018],[27.861328,66.124962],[27.773438,66.565747],[33.046875,66.774586],[33.925781,66.774586],[34.453125,66.739902],[34.936523,66.635556],[35.244141,66.49574],[37.089844,65.127638],[32.080078,64.960766],[31.289063,61.459771],[27.026367,61.396719],[26.982422,60.951777],[24.873047,60.930432],[24.433594,61.41775],[24.169922,61.731526],[24.125977,62.206512]]]}}',
          mark_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Point","coordinates":[31.289063,65.748683]}}',
          flats_available: 40,
          floors: []
        },
        {
          id: 2,
          name: "2",
          number: 2,
          is_hidden: false,
          display: null,
          poly_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Polygon","coordinates":[[[39.770508,63.801894],[37.924805,65.164579],[32.080078,64.960766],[31.289063,61.48076],[34.49707,61.606396],[34.716797,62.835089],[40.166016,63.035039],[39.506836,63.015106],[39.638672,63.450509],[39.726563,63.450509],[39.770508,63.801894]]]}}',
          mark_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Point","coordinates":[35.81543,63.821288]}}',
          flats_available: 32,
          floors: []
        },
        {
          id: 3,
          name: "3",
          number: 3,
          is_hidden: false,
          display: null,
          poly_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Polygon","coordinates":[[[40.166016,63.015106],[34.760742,62.835089],[34.49707,61.606396],[34.760742,61.606396],[36.123047,60.174306],[35.947266,59.153403],[36.079102,58.904646],[34.980469,53.592505],[41.791992,54.213861],[42.407227,54.342149],[42.626953,54.54658],[42.758789,54.977614],[42.978516,55.203953],[44.516602,60.651647],[44.34082,60.930432],[42.011719,62.471724],[40.825195,62.492028],[40.166016,63.015106]]]}}',
          mark_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Point","coordinates":[39.770508,61.33354]}}',
          flats_available: 25,
          floors: []
        },
        {
          id: 4,
          name: "4",
          number: 4,
          is_hidden: false,
          display: null,
          poly_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Polygon","coordinates":[[[34.980469,53.592505],[36.079102,58.904646],[35.947266,59.153403],[36.123047,60.174306],[34.760742,61.606396],[27.026367,61.396719],[26.982422,60.973107],[26.279297,60.973107],[27.729492,59.108308],[27.553711,58.240164],[27.729492,58.054632],[27.070313,53.225768],[27.026367,52.348763],[28.300781,52.456009],[28.344727,52.935397],[30.761719,53.041213],[30.9375,52.802761],[32.475586,52.935397],[32.475586,53.304621],[34.980469,53.592505]]]}}',
          mark_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Point","coordinates":[31.552734,60.305185]}}',
          flats_available: 18,
          floors: []
        },
        {
          id: 5,
          name: "5",
          number: 5,
          is_hidden: false,
          display: null,
          poly_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Polygon","coordinates":[[[22.456055,52.348763],[22.456055,52.187405],[27.026367,52.348763],[27.070313,53.225768],[27.729492,58.054632],[27.553711,58.240164],[27.729492,59.108308],[26.279297,60.973107],[23.950195,60.909073],[23.950195,60.543775],[21.708984,60.500525],[21.796875,60.174306],[19.6875,60.130564],[19.599609,59.265881],[20.214844,59.175928],[21.84082,56.607885],[21.225586,52.295042],[22.456055,52.348763]]]}}',
          mark_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Point","coordinates":[23.466797,59.063154]}}',
          flats_available: 11,
          floors: []
        },
        {
          id: 6,
          name: "6",
          number: 6,
          is_hidden: false,
          display: null,
          poly_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Polygon","coordinates":[[[13.974609,56.0475],[12.612305,58.539595],[15.161133,58.585436],[15.029297,58.790978],[18.149414,58.881942],[18.105469,59.108308],[20.214844,59.175928],[21.84082,56.607885],[21.225586,52.295042],[18.632813,52.160455],[18.500977,51.041394],[16.040039,50.958427],[15.644531,51.781436],[13.623047,51.6998],[13.974609,56.0475]]]}}',
          mark_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Point","coordinates":[16.918945,57.961503]}}',
          flats_available: 12,
          floors: []
        },
        {
          id: 8,
          name: "7",
          number: 7,
          is_hidden: false,
          display: null,
          poly_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Polygon","coordinates":[[[8.876953,49.21042],[5.712891,48.951366],[3.383789,48.777913],[3.47168,55.553495],[2.416992,58.054632],[3.999023,58.101105],[3.999023,58.470721],[12.480469,58.745407],[13.974609,56.0475],[13.535156,50.819818],[13.359375,50.708634],[13.40332,49.781264],[8.876953,49.21042]]]}}',
          mark_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Point","coordinates":[7.119141,57.112385]}}',
          flats_available: 27,
          floors: []
        },
        {
          id: 9,
          name: "8",
          number: 8,
          is_hidden: false,
          display: null,
          poly_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Polygon","coordinates":[[[-8.789063,57.539417],[-7.77832,57.562995],[-7.910156,58.147519],[-3.120117,58.378679],[-2.988281,58.12432],[-0.395508,58.193871],[-0.263672,57.984808],[2.416992,58.054632],[3.47168,55.553495],[3.383789,48.777913],[-5.449219,47.960502],[-5.976563,48.078079],[-6.064453,48.312428],[-6.459961,48.429201],[-6.943359,48.603858],[-7.382813,48.835797],[-7.69043,49.037868],[-7.998047,49.267805],[-8.261719,49.724479],[-8.4375,50.261254],[-8.525391,50.652943],[-8.569336,51.041394],[-8.789063,57.539417]]]}}',
          mark_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Point","coordinates":[-3.076172,56.096556]}}',
          flats_available: 30,
          floors: []
        },
        {
          id: 10,
          name: "9",
          number: 9,
          is_hidden: false,
          display: null,
          poly_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Polygon","coordinates":[[[-3.120117,58.378679],[-7.910156,58.147519],[-7.77832,57.562995],[-8.789063,57.539417],[-8.613281,51.944265],[-10.195313,51.835778],[-10.634766,52.268157],[-10.898438,53.618579],[-11.25,54.775346],[-11.206055,55.998381],[-11.425781,58.12432],[-11.425781,59.085739],[-10.898438,59.108308],[-11.118164,60.326948],[-8.481445,60.392148],[-8.393555,60.042904],[-4.482422,60.174306],[-4.526367,60.780619],[-4.042969,60.802064],[-3.120117,58.378679]]]}}',
          mark_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Point","coordinates":[-6.899414,59.153403]}}',
          flats_available: 31,
          floors: []
        },
        {
          id: 11,
          name: "10",
          number: 10,
          is_hidden: false,
          display: null,
          poly_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Polygon","coordinates":[[[-4.438477,60.802064],[-4.438477,60.951777],[-4.570313,61.41775],[-4.614258,62.492028],[-4.746094,62.995158],[-5.141602,62.995158],[-5.493164,63.66576],[-11.865234,63.489767],[-12.700195,62.552857],[-12.568359,60.844911],[-12.348633,58.147519],[-11.601563,55.702355],[-11.25,55.702355],[-11.425781,58.12432],[-11.425781,59.085739],[-10.898438,59.108308],[-11.118164,60.326948],[-8.481445,60.392148],[-8.393555,60.042904],[-4.482422,60.174306],[-4.526367,60.780619],[-4.438477,60.802064]]]}}',
          mark_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Point","coordinates":[-8.261719,62.124436]}}',
          flats_available: 31,
          floors: []
        },
        {
          id: 12,
          name: "11",
          number: 11,
          is_hidden: false,
          display: null,
          poly_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Polygon","coordinates":[[[-0.571289,65.42173],[0,64.129784],[0,58.904646],[-3.295898,58.859224],[-4.086914,60.802064],[-4.438477,60.823494],[-4.614258,62.492028],[-4.746094,62.995158],[-5.141602,62.995158],[-5.493164,63.66576],[-11.865234,63.489767],[-11.25,63.509375],[-11.469727,64.472794],[-10.019531,64.54844],[-10.063477,65.053602],[-0.571289,65.42173]]]}}',
          mark_genplan:
            '{"type":"Feature","properties":[],"geometry":{"type":"Point","coordinates":[-7.075195,64.510643]}}',
          flats_available: 42,
          floors: []
        }
      ]
    }
  ]
}
