import useSWR, { mutate } from "swr"

import { fetcher } from "shared/http"

export const useFloor = ({ building, section, floor }) => {
  return useSWR(["/genplan", building, section, floor].join("/"))
}

export const usePlacementById = (id, { print } = {}) => {
  return useSWR({
    url: "/genplan/placement",
    params: {
      uniCode: id,
      print
    }
  })
}

export const useSearchResults = params => {
  return useSWR(() => {
    return params
      ? {
          method: "post",
          url: "/search/list",
          data: params
        }
      : null
  })
}

export const useSearchFilterSettings = () => {
  return useSWR("/search", getSearchFilterSettings)
}

export const useGenplanSchema = () => {
  return useSWR({
    url: "/genplan/scheme"
  })
}

export const getSearchFilterSettings = () => {
  return fetcher("/search")
}

export const prefetchFloor = ({ building, section, floor }) => {
  const key = "/genplan/" + [building, section, floor].join("/")
  mutate(key, fetcher(key))
}

export const prefetchPlacement = id => {
  const key = {
    url: "/genplan/placement",
    params: {
      uniCode: id
    }
  }
  mutate(key, () => fetcher(key))
}
