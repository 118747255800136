import { ReactComponent as LogoIcon } from "../assets/logo.svg"
import { useContacts } from "shared/store"

export function PrintHeader() {
  const { phone, address } = useContacts()

  return (
    <div className="grid grid-cols-2 gap-[20px] border-black py-[10px]">
      <div>
        <LogoIcon className="h-[auto] w-[70px]" />
      </div>
      <div className="flex text-[12px] font-medium leading-[1.2]">
        <div className="flex-auto">{phone}</div>
        <div className="max-w-[140px] flex-none text-right">
          {address.office}
          <div>пн-пт 9:00 – 18:00</div>
        </div>
      </div>
    </div>
  )
}
