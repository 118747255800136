const URL = "https://itef.ru"
export function Itef() {
  return (
    <a href={URL} target="_blank" rel="noreferrer">
      <div className="font-tenorsans text-[12px] uppercase leading-[14px]">
        <span className="opacity-50">сайт от</span> <span>ITEF</span>
      </div>
    </a>
  )
}
