import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, EffectFade } from "swiper"
import { useEffect, useState } from "react"

import { Progress } from "shared/components/progress"
import { addMockProps } from "shared/helpers"
import { cx } from "shared/helpers"
import { mock } from "../mock"

SwiperCore.use([EffectFade, Autoplay])

export const Section10 = addMockProps(function Section10({ slides }) {
  const [active, setActive] = useState(0)
  const [tab, setTab] = useState(slides[0]?.id || 0)
  const [swiper, setSwiper] = useState()
  const [textSwiper, setTextSwiper] = useState()

  const params = {
    fadeEffect: {
      crossFade: true
    },
    effect: "fade",
    loop: false,
    speed: 1000,
    spaceBetween: 8,
    slidesPerView: "auto"
  }

  useEffect(() => {
    if (swiper && textSwiper && swiper.controller) swiper.controller.control = textSwiper
  }, [swiper, textSwiper])

  return (
    <div className="relative pt-[50px] text-brown-100 xl:pt-[90px]">
      <div className="md:grid-12 px-[16px] md:px-[45px]">
        <div className="h1 col-start-1 col-end-6">Преимущества</div>
        <div className="h2 col-start-6 col-end-13 max-md:mt-[47px]">
          Лес в окне -<br /> море впечатлений во дворе
        </div>
      </div>
      <div className="mt-[34px] grid grid-cols-2 gap-[20px] max-md:hidden xl:mt-[46px]">
        <div className="flex flex-col gap-[15px] pl-[45px]">
          <div className="grid flex-none grid-cols-6 gap-[20px]">
            <div className="col-start-1 col-end-2">
              <Progress list={slides} active={active} variant="browndark" />
            </div>
            <div className="col-start-2 col-end-7 grid md:gap-[20px] xl:gap-[25px]">
              {slides.map((item, index) => (
                <div
                  key={item.id}
                  className={cx(
                    "h5 cursor-pointer transition",
                    index === active ? "text-brown-100" : "text-brown-100/50"
                  )}
                  onClick={() => {
                    swiper.slideTo(index)
                  }}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
          <div className="flex grid flex-auto grid-cols-6 items-end gap-[20px] md:pb-[50px]">
            <div className="col-start-2 col-end-7 text-[16px] leading-[1.5] md:max-w-[470px] md:text-[14px] xl:max-w-[600px] xl:text-[20px]">
              <Swiper onSwiper={setTextSwiper} {...params}>
                {slides.map(item => (
                  <SwiperSlide key={item.id} className="w-full">
                    {item.text}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        <div>
          <Swiper onSwiper={setSwiper} {...params} onSlideChange={swiper => setActive(swiper.realIndex)}>
            {slides.map(item => (
              <SwiperSlide key={item.id} className="w-full">
                <img alt="" src={item.src} className="h-full w-full object-cover" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="mt-[30px] grid gap-[20px] px-[16px] md:hidden">
        {slides.map(({ id, name, text, src }) => (
          <div
            key={id}
            onClick={() => {
              setTab(id)
            }}
            className={cx(
              "overflow-hidden transition-all duration-1000",
              id === tab ? "max-h-[600px] text-brown-100" : "max-h-[25px] text-brown-100/50"
            )}
          >
            <div className="h4">{name}</div>
            <div className="mt-[20px] grid gap-[20px]">
              <div className="ratio-square">
                <img alt="" src={src} className="block h-full w-full object-cover" />
              </div>
              {text && <div className="text">{text}</div>}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}, mock.finish)
