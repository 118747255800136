import useSWR from "swr"

export const useGallery = () => {
  return useSWR({
    url: "/gallery"
  })
}

export const useGalleryDetail = id => {
  return useSWR({
    url: `/gallery/${id}`
  })
}
