import { useComplex } from "shared/store"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export const SuncalcPage = () => {
  const { location, topViewUrl } = useComplex()
  const navigate = useNavigate()

  useEffect(() => {
    const createSuncalc = window.iSuncalcReady
    const position = location !== "" ? location.split(",") : []
    if (typeof createSuncalc === "function" && location.length > 0) {
      createSuncalc({
        image: topViewUrl,
        position: position,
        onClose: () => navigate("/genplan")
      })
    }
  }, [navigate, location, topViewUrl])

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0">
      <div id="suncalc" />
    </div>
  )
}
