import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/controller"
import "swiper/css/lazy"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Controller, EffectFade, Lazy, Navigation } from "swiper"
import { ReactComponent as ArrowNextIcon } from "../assets/arrow-next.svg"
import { ReactComponent as ArrowPrevIcon } from "../assets/arrow-prev.svg"
import { cx } from "shared/helpers"
import { useState } from "react"

SwiperCore.use([Autoplay, EffectFade, Navigation, Lazy, Controller])

export function SliderSm({ slides = [], variant = "default" }) {
  const [active, setActive] = useState(0)

  let params = {
    preloadImages: false,
    lazy: true,
    navigation: {
      prevEl: ".swiper-prev",
      nextEl: ".swiper-next"
    },
    effect: "fade",
    loop: false,
    speed: 500,
    slidesPerView: 1,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    onSlideChange: swiper => setActive(swiper.realIndex)
  }

  return (
    <div className="relative h-full">
      <Swiper {...params} className="h-full">
        {slides.map(({ src, id }) => (
          <SwiperSlide key={id} className="h-full">
            <img loading="lazy" className="h-[100%] w-[100%] object-cover" alt="" src={src} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className={cx(
          "absolute left-0 right-0 z-[10] flex justify-between px-[16px] text-white",
          variant === "high" ? "bottom-[20px]" : "bottom-0"
        )}
      >
        <div
          className={cx("swiper-prev cursor-pointer", {
            "text-white/50": active === 0
          })}
        >
          <ArrowPrevIcon />
        </div>
        <div
          className={cx("swiper-next mt-[4px] cursor-pointer", {
            "text-white/50": active + 1 === slides.length
          })}
        >
          <ArrowNextIcon />
        </div>
      </div>
    </div>
  )
}
