import { GalleryLight } from "shared/components/gallery"
import { useGalleryDetail } from "../api"
import { useParams } from "react-router-dom"
import { useThrowError } from "shared/hooks"

export const GalleryDetailPage = () => {
  const params = useParams()
  const id = params.id
  const { error, data: { data = [] } = {} } = useGalleryDetail(id)
  useThrowError(error)

  const files = data?.files || []

  return (
    <GalleryLight
      files={files}
      name={data.name}
      closePath="/gallery"
      cover={false}
      swiperParams={{
        autoplay: false,
        preloadImages: true
      }}
    />
  )
}
